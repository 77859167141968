import React from "react";
// material-ui components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";

import imagesStyles from "assets/jss/material-kit-react/imagesStyles.js";

import { cardTitle } from "assets/jss/material-kit-react.js";

const styles = {
  ...imagesStyles,
  cardTitle,
};

const useStyles = makeStyles(styles);

export default function CardWithImage({image, width, partner, content, website}) {
    const classes = useStyles();
    const imageWidth = width ? width : "auto";
    return (
        <Card style={{}}>
            <img
            style={{height: "100px", width: imageWidth, maxWidth: "250px", marginLeft: "auto",
            marginRight: "auto"}}
            className={classes.imgCardTop}
            src={image}
            alt="Card-img-cap"
        />
            <CardBody style={{height: '310px'}}>
                <h4 className={classes.cardTitle}>{partner}</h4>
                <p style={{textAlign: 'justify'}}>{content}</p>
                <a  href={website}
                    className={classes.cardLink}
                    target="_blank">
                    En savoir plus
                </a>
            </CardBody>
        </Card>
  );
}