import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Hidden from '@material-ui/core/Hidden';
import Link from '@material-ui/core/Link';
import Button from "components/CustomButtons/Button.js";
import Divider from '@material-ui/core/Divider';
import Container from '@material-ui/core/Container';

import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";



import Markdown from './Markdown';
import post1 from './blog-post.1.md.js';
import post2 from './blog-post.2.md.js';
import post3 from './blog-post.3.md.js';

import patient from "assets/img/patient-painkillar.jpg";
import girlvr from "assets/img/vr-girl.jpg";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  toolbar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbarTitle: {
    flex: 1,
  },
  toolbarSecondary: {
    justifyContent: 'space-between',
    overflowX: 'auto',
  },
  toolbarLink: {
    padding: theme.spacing(1),
    flexShrink: 0,
  },
  mainFeaturedPost: {
    position: 'relative',
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.common.white,
    marginBottom: theme.spacing(4),
    backgroundImage: `url(${girlvr})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: 'rgba(0,0,0,.3)',
  },
  mainFeaturedPostContent: {
    position: 'relative',
    padding: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(6),
      paddingRight: 0,
    },
  },
  mainGrid: {
    marginTop: theme.spacing(10),
  },
  card: {
    display: 'flex',
  },
  cardDetails: {
    flex: 1,
  },
  cardMedia: {
    width: 160,
  },
  markdown: {
    ...theme.typography.body2,
    padding: theme.spacing(3, 0),
  },
  sidebarAboutBox: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.grey[200],
  },
  sidebarSection: {
    marginTop: theme.spacing(3),
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(8),
    padding: theme.spacing(6, 0),
  },
}));

const sections = [
  'Technology',
  'Design',
  'Culture',
  'Business',
  'Politics',
  'Opinion',
  'Science',
  'Health',
  'Style',
  'Travel',
];

const featuredPosts = [
  {
    title: 'Pourquoi on a créé PainkillAR',
    date: 'AVRIL 2020',
    description: "Dans cet article je vous raconte la génèse de la société de l'idée à la création.",
    link: "/blog/why-painkillar",
    image: require("assets/img/LOGO-CARRE-PAINKILLAR.jpg")
  },
  {
    title: "Lindra, c'est quoi ?",
    date: 'MAI 2020',
    description: "On vous explique comment marche notre application de gestion de la douleur en réalité virtuelle.",
    link: "/blog/what-is-lindra",
    image: require("assets/img/FullLogo.png")
  },
  {
    title: "Comment parler de douleur avec son docteur ?",
    date: 'JUIN 2020',
    description: "Quelques conseils pour tirer le plus de bénéfices d’une consultation avec votre spécialiste de la douleur.",
    link: "/blog/howto-talk-to-doctor",
    image: require("assets/img/female-doctor.jpg")
  },
  {
    title: 'Hommage au Dr. John Bonica',
    date: 'AOÛT 2020',
    description: "Ce 15 août, on fête l’anniversaire de la mort de John Bonica, pionnier de la médecine de la douleur.",
    link: "/blog/john-bonica",
    image: require("assets/img/bonica-portrait.jpg")
  },
  {
    title: 'Douleur & Sommeil - 1ère Partie',
    date: 'AOÛT 2020',
    description: "Dans ce post en 2 parties on analyse la relation entre douleur et sommeil et donne des tips pour mieux dormir.",
    link: "/blog/sleep-and-pain",
    image: require("assets/img/sleep.jpg")
  },
  {
    title: 'Douleur & Sommeil - 2è Partie',
    date: 'SEPT 2020',
    description: "Dans ce post en 2 parties on analyse la relation entre douleur et sommeil et donne des tips pour mieux dormir.",
    link: "/blog/sommeil-et-douleur-part-2",
    image: require("assets/img/sleep2.jpg")
  }
];

const posts = [post1, post2, post3];

const archives = [
  'March 2020',
  'February 2020',
  'January 2020',
  'December 2019',
  'November 2019',
  'October 2019',
  'September 2019',
  'August 2019',
  'July 2019',
  'June 2019',
  'May 2019',
  'April 2019',
];

const social = ['GitHub', 'Twitter', 'Facebook'];

export default function PainkillARBlog(props) {
  const classes = useStyles();
  const { ...rest } = props;

  return (
    <React.Fragment>
            <Header
        color="darkPK"
        brand=" BLOG"
        rightLinks={<HeaderLinks />}
        fixed
        {...rest}
      />
      <CssBaseline />
      <Container maxWidth="lg">
        <Toolbar className={classes.toolbar}>

        </Toolbar>
        <Toolbar component="nav" variant="dense" className={classes.toolbarSecondary}>
          
        </Toolbar>
        <main>
          {/* Main featured post */}
          <Paper className={classes.mainFeaturedPost}>
            {/* Increase the priority of the hero background image */}
            {
              <img
                style={{ display: 'none' }}
                src={patient}
                alt="background"
              />
            }
            <div className={classes.overlay} />
            <Grid container>
              <Grid item md={6}>
                <div className={classes.mainFeaturedPostContent}>
                  <Typography component="h1" variant="h3" color="inherit" gutterBottom>
                    La Réalité Virtuelle comme Solution contre la Douleur
                  </Typography>
                  <Typography variant="h5" color="inherit" paragraph>
                  La technologie offre de nouvelles options pour celles et ceux qui souffrent de douleur chronique.
                  </Typography>
                  <Button
                    href="/blog/vr-pain"
                    color="primary">
                    LIRE L'ARTICLE
                  </Button>

                </div>
              </Grid>
            </Grid>
          </Paper>
          {/* End main featured post */}
          {/* Sub featured posts */}
          <Grid container spacing={4}>
            {featuredPosts.slice(0).reverse().map(post => (
              <Grid item key={post.title} xs={12}>
                <CardActionArea component="a" href={post.link}>
                  <Card className={classes.card}>
                    <div className={classes.cardDetails}>
                      <CardContent>
                        <Typography component="h2" variant="h5">
                          {post.title}
                        </Typography>
                        <Typography variant="subtitle1" color="textSecondary">
                          {post.date}
                        </Typography>
                        <Typography variant="subtitle1" paragraph>
                          {post.description}
                        </Typography>
                        <Button
                    href={post.link}
                    color="primary">
                    LIRE L'ARTICLE
                  </Button>
                      </CardContent>
                    </div>
                    <Hidden xsDown>
                      <CardMedia
                        className={classes.cardMedia}
                        image={post.image}
                        title="Image title"
                      />
                    </Hidden>
                  </Card>
                </CardActionArea>
              </Grid>
            ))}
          </Grid>
          {/* End sub featured posts */}
          
        </main>
      </Container>
      <Footer />

    </React.Fragment>
  );
}
