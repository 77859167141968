import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Camera from "@material-ui/icons/Camera";
import Palette from "@material-ui/icons/Palette";
import Favorite from "@material-ui/icons/Favorite";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import NavPills from "components/NavPills/NavPills.js";
import Parallax from "components/Parallax/Parallax.js";
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import SubIcon from '@material-ui/icons/Subscriptions';

import profile from "assets/img/team/Guillaume-Palacios-200x200.jpg";


import thermalthreshold from "assets/img/thermalthreshold.png";
import brainactivity from "assets/img/brainactivity.png";


import styles from "assets/jss/material-kit-react/views/blogPost.js";

const useStyles = makeStyles(styles);

export default function SleepAndPain(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);
  return (
    <div>
      <Header
        color="transparent"
        brand=" BLOG"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "darkPK"
        }}
        {...rest}
      />
      <Parallax small filter image={require("assets/img/sleep.jpg")} />
      <div className={classNames(classes.main)}>
        <div>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={6}>
                <div className={classes.profile}>
                  <div>
                    <img src={profile} alt="..." className={imageClasses} />
                  </div>
                  <div className={classes.name}>
                    <h3 className={classes.title}>DOULEUR & SOMMEIL — 1ère PARTIE</h3>
                    <h6>Guillaume Palacios, PhD</h6>
                    <h6>co-fondateur de PainkillAR</h6>
                    {/* <Button justIcon link className={classes.margin5}>
                      <i className={"fab fa-twitter"} />
                    </Button>
                    <Button justIcon link className={classes.margin5}>
                      <i className={"fab fa-instagram"} />
                    </Button>
                    <Button justIcon link className={classes.margin5}>
                      <i className={"fab fa-facebook"} />
                    </Button> */}
                  </div>
                </div>
              </GridItem>
            </GridContainer>
            <div className={classes.abstract}>
            Chacun d’entre nous qui souffre de douleurs chroniques sait à quel point cela a une incidence négative sur la qualité de vie. Stress, dépression et surtout impact sur la quantité et la qualité du sommeil. Dans ce post en 2 parties on 1) analyse la relation entre douleur et sommeil et 2) donne des “tips” pour mieux dormir.
            </div>

            <h3 className={classes.sectionHeader}>Introduction</h3>
<div className={classes.text}>
C’est maintenant un leitmotiv dans tous les blogs de bien-être : le sommeil est un élément absolument essentiel dans la santé. Dans un monde débordant de sollicitations (la plupart totalement inutiles) notre cerveau est constamment en mode alerte. Répondre immédiatement à un email au bureau. Alerte ! Stress ! Regardez si mon post Instagram a été liké par mes copines. Alerte ! Stress ! Il est 2h du matin mais il ne reste plus qu’un épisode pour finir la dernière saison de Casa de Papel sur Netflix. Vie privée, enfants à gérer, stress !
</div>

<div className={classes.text}>
Alors si en plus on rajoute à cela une pathologie engendrant une douleur ou un stress chronique, c’est le système nerveux qui est sollicité 24h/24 et ça crashe.
</div>

<div className={classes.text}>
Dans cet article nous abordons la question du rapport conflictuel entre sommeil et douleur chronique
</div>

            <h3 className={classes.sectionHeader}>Pourquoi un sommeil de qualité est si important pour la santé ?</h3>
<div className={classes.text}>
Avoir un sommeil de qualité est absolument fondamental pour une vie équilibrée et en bonne santé. Le manque de sommeil est associé à l’hypertension artérielle, au diabète, aux accidents vasculaires cérébraux, aux maladies cardiaques et à la dépression. C’est d’autant plus primordial pour les personnes souffrant de douleur chronique qui ont souvent des pathologies sous-jacentes et qui, de fait, ont une tendance accrue au stress, à l’insomnie et à la dépression.
</div>


            <h3 className={classes.sectionHeader}>Qu’est-ce qu’un sommeil de qualité ?</h3>
<div className={classes.text}>
C’est d’abord suffisamment de temps de sommeil nocturne. Cela dépend des personnes mais pour un adulte c’est 7 à 9 heures de sommeil sans interruption. Or, selon les statistiques de la https://www.sleepassociation.org/, plus de 35% des adultes aux USA (les statistiques sont les mêmes en Europe) dorment moins de 7 heures par nuit.
</div>

<div className={classes.text}>
S’il est important d’avoir ses 8 heures de sommeil par nuit, il est également conseillé de ne pas trop dormir ni de rester au lit jusqu’à midi, et ce même le week-end ! Cette affirmation peut paraître paradoxale : le “bon sens” voudrait que “plus je dors, plus je récupère”. Mais ce n’est pas comme ça que ça se passe. Les problèmes de santé liés au sommeil apparaissent dès lors qu’on ne dort pas suffisamment mais aussi quand on dort au mauvais moment. Rien ne vaut 8 heures de sommeil de 23h à 7h du matin. Les siestes peuvent être utiles surtout pour les personnes souffrantes mais dans ce cas, pas plus de 20 min et en début d’après-midi.
</div>

            <h3 className={classes.sectionHeader}>Pourquoi la douleur perturbe-t-elle le sommeil ?</h3>

<div className={classes.text}>
Le sommeil se décompose en cycles de 4 phases. La douleur perturbe nos cycles REM (Rapid Eye Movement). Chaque nuit, nous passons par le sommeil léger, le sommeil profond et le sommeil paradoxal. Cependant, la douleur vous réveille souvent, même si vous ne vous en rendez pas compte. Cela empêche votre corps d’entrer en sommeil paradoxal, passant ainsi toute la nuit dans un sommeil léger. Ainsi, vous pouvez avoir l’impression de dormir toute la nuit, mais si le sommeil paradoxal n’a jamais été atteint, votre corps ne se sentira pas reposé.
</div>

<div className={classes.text}>
Pour les douloureux chroniques (arthrose, lombalgie, douleurs neuropathiques), la plupart des nuits sont passées à tourner et à tourner, à ajuster les oreillers et à essayer toutes les tactiques possibles pour trouver une position confortable. L’endormissement devient alors très compliqué.
</div>

<div className={classes.text}>
Attention aux analgésiques qui perturbent le sommeil ainsi que les antidépresseurs (souvent prescrits en douleur chronique) [M. Haack et al. 2019].
</div>

<div className={classes.text}>
Autre point pouvant expliquer le sommeil difficile de certains patients douloureux chroniques : la fréquente comorbidité entre douleur chronique et apnée du sommeil. L’apnée du sommeil est très corrélée à une prise de poids, autre sujet fâcheux chez nous autres douloureux chroniques. En effet, qui dit douleur dit diminution de l’activité physique. Sans compter certains médicaments anti-inflammatoires tels que les corticoïdes qui peuvent entraîner des prises de poids également. Pour lutter contre cela, régime et activité physique, même modérée, sont à mettre en oeuvre.
</div>

<h3 className={classes.sectionHeader}>Réciproquement, le manque de sommeil exacerbe la sensibilité à la douleur</h3>
<div className={classes.text}>
La privation de sommeil vous rend plus sensible à la douleur. Il est impossible de bien commencer la journée lorsque vos premières pensées le matin sont a) à quel point vous avez mal dormi et b) à quel point vous ressentez de la douleur. Et cela crée un cercle vicieux : j’ai mal donc je dors mal, et moins je dors plus je suis sensible à la douleur.
</div>

<h3 className={classes.sectionHeader}>La Science</h3>
<div className={classes.text}>
L’explication scientifique de cette hypersensibilité à la douleur liée à une privation de sommeil est récente. Un excellent article de [Krause et al. 2019] (équipe de Walker à Berkeley) publié en mars 2019 montre clairement que la résistance à un stimulus douloureux est décrue après une nuit de veille (cf. Fig. 1 ci-dessous).
</div>

<div className={classes.text}>
L’étude montre, grâce à l’utilisation de fMRI (IRM fonctionnelle), qu’en effet, la privation de sommeil augmente d’une manière significative la réaction à la douleur dans la zone du cortex sensori-moteur, zone de cerveau connue pour être impliquée dans la sensation de douleur [Atlas et al 2014]. Cette augmentation de l’activité corticale s’accompagne d’une baisse de l’activité dans les régions du NAcc (Noyau accumbens), du thalamus et de l’insula (cf Fig. 2). Le réseau cérébral NAcc, thalamus avec l’insula comme organe régulateur est quant à lui responsable de la prise de décision et de l’évaluation plus subjective de la douleur.
</div>
<GridContainer justify="center">
              <GridItem xs={12} sm={12} md={8} className={classes.navWrapper}>
                        <GridContainer justify="center">
                          <GridItem xs={12} sm={12} md={8}>
                            <img
                              alt="..."
                              src={thermalthreshold}
                              className={navImageClasses}
                            />
Suite à une privation de sommeil, il y avait une augmentation de la sensibilité à la douleur démontrée par un abaissement significatif des seuils de douleur, par rapport au sommeil reposé.                          </GridItem>
                        </GridContainer>
              </GridItem>
            </GridContainer>
<div className={classes.text}>
D’un point de vue neuro-pharmacologique, je vous invite à lire la revue du groupe de Mullington à Harvard [Haack et al. 2020] publié dans Neuropsychopharmacology et qui décrit les effets du manque de sommeil sur différents systèmes impliqués dans la réponse douloureuse (système nociceptif) : le système opioïde, monoaminergique, orexinergique, le système immunitaire, le cycle de la mélatonine, le système endocannabinoïde, la recapture de l’adénosine (impliquée dans la sensation de fatigue) etc. L’article développe en détail les relations entre la perturbation de ces fragiles systèmes neurochimiques et la douleur chronique. Passionnant.
</div>

<h3 className={classes.sectionHeader}>Conclusion</h3>
<div className={classes.text}>
Recouvrer un sommeil de qualité est un élément essentiel vers une meilleure qualité de vie en tant que douloureux chronique. Le but est double :
  <ul>
  <li>
  un objectif de prévention : se prémunir des complications de sa propre pathologie et prévenir d’éventuelles comorbidités (pression artérielle, dépression chronique, risque d’Alzheimer)
  </li>
<li>
un objectif thérapeutique : comme tendent à le montrer les études des équipes de Walker à Berkeley et de Mullington à Harvard, avoir un sommeil suffisant et de qualité permet d’augmenter sensiblement la tolérance à la douleur. C’est donc un outil thérapeutique puissant totalement gratuit et non-médicamenteux. Il ne tient qu’aux docteurs à adresser le problème du sommeil chez les douloureux chroniques et surtout qu’aux patients à prendre la gestion de leur sommeil à bras le corps, de mettre en place une stratégie visant à mieux dormir et surtout, de s’y tenir dans la durée. Le jeu en vaut la chandelle.
</li>
  </ul>
</div>

<div className={classes.text}>
Dans une 2è partie, nous donnerons des conseils pratiques pour mieux dormir quand on est douloureux chronique. À la prochaine !
</div>
<GridContainer justify="center">
              <GridItem xs={12} sm={12} md={8} className={classes.navWrapper}>
                        <GridContainer justify="center">
                          <GridItem xs={12} sm={12} md={8}>
                          <Button
          href="https://www.lindra.co"
          color="rose"
          target="_blank"
        >
          <SubIcon/>testez la réalité virtuelle anti-douleur
        </Button>
                          </GridItem>
                        </GridContainer>
              </GridItem>
            </GridContainer>

            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={8} className={classes.navWrapper}>
                        <GridContainer justify="center">
                          <GridItem xs={12} sm={12} md={8}>
                            <img
                              alt="..."
                              src={brainactivity}
                              className={navImageClasses}
                            />
Modifications de l’activité cérébrale liée à la douleur. Les graphiques à barres représentent les estimations de contraste de l’activité cérébrale (douleur> pas de douleur). Suractivité dans le Somatosensoriel. Inhibition dans le complexe NAcc, Thalamius, Insula antérieure, Insula moyenne.                          </GridItem>
                        </GridContainer>
              </GridItem>
            </GridContainer>


<h3 className={classes.sectionHeader}>Références</h3>
<div className={classes.text}>
[Krause et al. 2019] Adam J. Krause, Aric A. Prather, Tor D. Wager, Martin A. Lindquist, Matthew P. Walker. The Pain of Sleep Loss: A Brain Characterization in Humans. Journal of Neuroscience 20 March 2019, 39 (12) 2291–2300; DOI: 10.1523/JNEUROSCI.2408–18.2018
<br/>
[Atlas et al 2014] Atlas LY, Lindquist MA, Bolger N, Wager TD. Brain mediators of the effects of noxious heat on pain. Pain. 2014;155(8):1632–1648. doi:10.1016/j.pain.2014.05.015
<br/>
[Haack et al. 2020] Haack, M., Simpson, N., Sethna, N. et al. Sleep deficiency and chronic pain: potential underlying mechanisms and clinical implications. Neuropsychopharmacol. 45, 205–216 (2020). https://doi.org/10.1038/s41386-019-0439-z
</div>



            
            
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={8} className={classes.navWrapper}>
                        <GridContainer justify="center">
                          <GridItem xs={12} sm={12} md={6}>
                          <Button
                            target="_blank" 
                            href="https://twitter.com/intent/tweet?text=Histoire%20de%20PainkillAR%20Réalité%20Virtuelle%20Anti-Douleur&via=Painkill_AR&url=https%3A%2F%2Fpainkillar.com%2Fblog%2Fsleep-and-pain"
                            color="twitter">
        <i
          className={"fab fa-twitter"}
        />{" "}
        Partager sur Twitter
      </Button>
      </GridItem>
                          <GridItem xs={12} sm={12} md={6}>
                          <Button 
                          color="facebook"
                          target="_blank" 
                          href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fpainkillar.com%2Fblog%2Fsleep-and-pain&amp;src=sdkpreparse"
                          >
        <i
          className={" fab fa-facebook-square"}
        />{" "}
        Partager sur Facebook
      </Button>
                          </GridItem>
                        </GridContainer>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
