import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// nodejs library that concatenates classes
import classNames from "classnames";

// @material-ui/icons
import Chat from "@material-ui/icons/Chat";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import Fingerprint from "@material-ui/icons/Fingerprint";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/useStyle.js";
import { Box } from "@material-ui/core";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardContent from '@material-ui/core/CardContent';

import CardMedia from '@material-ui/core/CardMedia';

import CardFooter from "components/Card/CardFooter.js";
import Button from "components/CustomButtons/Button.js";
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import Hidden from '@material-ui/core/Hidden';

import step1 from "assets/img/Card1.png";
import step2 from "assets/img/Card2.png";
import step3 from "assets/img/Card3.png";


const useStyles = makeStyles(styles);

export default function UseSection() {
  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  return (
    <div className={classes.section}>
      <div>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={12}>
          <h2 className={classes.title}>Très simple à utiliser </h2>

        </GridItem>
      </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={8}>
            <Card>
                <CardBody>
                    <GridContainer>
                    <GridItem xs={12} sm={12} md={6} spacing={1}>
                        <CardMedia
                            component="img"
                            className={classes.cardImage} 
                            image={step1}
                            title="Card1"
                        />
                            
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6} spacing={1}>
                        <h4 className={classes.cardTitle}>1. Inscrivez-vous et téléchargez l'application Lindra.</h4>
                        <CardBody>
                            <p className={classes.description}>
                            Une fois votre abonnement pris, vous recevrez un e-mail à l'adresse que vous nous aurez 
                            communiquée lors de votre inscription. Il contiendra un lien vous renvoyant vers le
                             Play Store vous permettant de télécharger Lindra.
                            </p>
                        </CardBody>
                    </GridItem>
    
                    </GridContainer>
                </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

        <GridContainer>
            <GridItem xs={12} sm={12} md={4} >
            </GridItem>
            <GridItem xs={12} sm={12} md={8} >
                <Card>
                    <CardBody>
                        <GridContainer>
                        <GridItem xs={12} sm={12} md={6} spacing={1}>
                        <CardMedia
                            component="img"
                            className={classes.cardImage} 
                            image={step2}
                            title="Card2"
                        />
                            
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6} spacing={1}>
                            <h4 className={classes.cardTitle}>2. Lancez l'application et insérez votre téléphone dans le casque
                            Cardboard</h4>
                            <CardBody>
                                <p className={classes.description}>
                                Peu après votre inscription, vous recevrez chez vous un casque Google Cardboard. Celui-ci est nécéssaire 
                                à l'utilisation de Lindra et vous permettra de profiter d'un environnement de Réalité Virtuelle
                                 de façon simple et économique.
                                </p>
                            </CardBody>
                        </GridItem>
                        </GridContainer>
                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>

        <GridContainer direction="row" justify="space-evenly" alignItems="center">
            <GridItem xs={12} sm={12} md={8} >
                <Card>
                    <CardBody>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={6} spacing={1}>
                            <CardMedia
                                component="img"
                                className={classes.cardImage} 
                                image={step3}
                                title="Card3"
                            />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6} spacing={1}>
                                <h4 className={classes.cardTitle}>3. Mettez le casque et commencez l'expérience</h4>
                                <CardBody>
                                    <p className={classes.description}>
                                    Vous pouvez maintenant mettre le casque et vous laisser porter.Choisissez la durée de l'expérience
                                     (3, 5 ou 10 minutes). L'application vous guidera ensuite afin de commencer l'expérience.  
                                     <br /> Bon voyage dans l'univers de Lindra.
                                    </p>
                                </CardBody>
                            </GridItem>
                        </GridContainer>
                    </CardBody>
                </Card>
            </GridItem>
            <Hidden mdDown>
                <GridItem xs={12} sm={12} md={4} >
                    <Button
                        color="rose"
                        size="lg"
                        href="https://app.lindra.co/register"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <ShoppingCartIcon/>

                        Je m'inscris
                    </Button>
                </GridItem>
            </Hidden>
            <Hidden lgUp>
                <GridItem xs={12} sm={12} md={12} >
                    <div className={classes.center}>
                        <Button
                            color="rose"
                            size="lg"
                            href="https://app.lindra.co/register"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <ShoppingCartIcon/>

                            Je m'inscris
                        </Button>
                    </div>  
                </GridItem>
            </Hidden>
            
        </GridContainer>
      </div>
    </div>
  );
}