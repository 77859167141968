import 'react-app-polyfill/ie11';
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";

import "assets/scss/material-kit-react.scss?v=1.8.0";
import './fonts/Rounded_Elegance.ttf'

// pages for this product
//import Components from "views/Components/Components.js";
import LandingPage from "views/LandingPage/LandingPagePainkillAR.js";
import Blog from "views/Blog/PainkillARBlog.js";
import WhyPainkillAR from "views/Blog/WhyPainkillAR.js";

import VRPain from "views/Blog/VRPain.js";
import WhatIsLindra from 'views/Blog/WhatIsLindra.js';
import TalkToDoctor from 'views/Blog/TalkToDoctor';
import JohnBonica from 'views/Blog/JohnBonica';
import SleepAndPain from 'views/Blog/SleepAndPain';
import SleepAndPain2 from 'views/Blog/SleepAndPainPart2';


var hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <Switch>
    <Route exact path="/blog" component={Blog} />
      <Route path="/blog/vr-pain" component={VRPain} />
      <Route path="/blog/why-painkillar" component={WhyPainkillAR} />
      <Route path="/blog/what-is-lindra" component={WhatIsLindra} />
      <Route path="/blog/howto-talk-to-doctor" component={TalkToDoctor} />
      <Route path="/blog/john-bonica" component={JohnBonica} />
      <Route path="/blog/sleep-and-pain" component={SleepAndPain} />
      <Route path="/blog/sommeil-et-douleur-part-2" component={SleepAndPain2} />
      {/* <Route path="/profile-page" component={ProfilePage} />
      <Route path="/login-page" component={LoginPage} /> */}
      {/* <Route path="/cgu" component={CGU} />
      <Route path="/privacy-breathar" component={PrivacyBreathAR} /> */}
      <Route path="/" component={LandingPage} />
    </Switch>
  </Router>,
  document.getElementById("root")
);
