import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";

// @material-ui/icons
import Chat from "@material-ui/icons/Chat";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import Fingerprint from "@material-ui/icons/Fingerprint";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";


import Camera from "@material-ui/icons/Camera";
import Palette from "@material-ui/icons/Palette";
import Favorite from "@material-ui/icons/Favorite";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import Button from "components/CustomButtons/Button.js";

import HeaderLinks from "components/Header/HeaderLinks.js";
import NavPills from "components/NavPills/NavPills.js";
import Parallax from "components/Parallax/Parallax.js";


import studio1 from "assets/img/examples/studio-1.jpg";
import studio2 from "assets/img/examples/studio-2.jpg";
import studio3 from "assets/img/examples/studio-3.jpg";
import studio4 from "assets/img/examples/studio-4.jpg";
import studio5 from "assets/img/examples/studio-5.jpg";
import work1 from "assets/img/examples/olu-eletu.jpg";
import work2 from "assets/img/examples/clem-onojeghuo.jpg";
import work3 from "assets/img/examples/cynthia-del-rio.jpg";
import work4 from "assets/img/examples/mariya-georgieva.jpg";
import work5 from "assets/img/examples/clem-onojegaw.jpg";


import styles from "assets/jss/material-kit-react/views/paragraphSectionStyle.js";

//import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(styles);

export default function DNASection() {
  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);
  return (
    <div id="DNA" className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>Notre ADN</h2>
          <div className={classes.description}>
              <p>
                <b>PainkillAR</b> est une startup parisienne créée fin 2018 et incubée à TELECOM ParisTech. 
              </p>
              <p>
                Nous sommes une <b>entreprise de R&D</b> qui développe des <b>solutions non-médicamenteuses</b> et <b>faciles à utiliser</b> contre la <b>douleur</b>. Nous faisons de la recherche dans le domaine des neurosciences et des nouvelles technologies (Réalité Virtuelle et Augmentée, Intelligence Artificielle) pour la mise au point de ces solutions.
              </p>
              <p>
                <b>Nous sommes une entreprise créée par des patients et pour les patients</b>.
                PainkillAR est née de l'obsession
                de Guillaume, notre CEO, pour trouver un remède à ses algies faciales et ses douleurs neuropathiques.
              </p>
              <p>
              En <b>France</b>, ce sont 12 millions de personnes qui souffrent de <b>douleurs quotidiennes</b> et sont victimes de surconsommation de médicaments et de leurs <b>effets indésirables</b>. 
              Ces patients errent de cabinets en cabinets à la recherche de <b>solutions alternatives rarement efficaces, souvent coûteuses</b>. 
              </p>
              <p>
                <b>
                  Notre mission est d'aider tous ces patients, en ville comme à l'hôpital, en leur offrant des outils puissants, 
                  faciles d'accès et sans dépendance pour gérer leur douleur. 
                </b>
              </p>
            </div>

        </GridItem>
      </GridContainer>

          </div>
  );
}
