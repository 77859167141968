import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Camera from "@material-ui/icons/Camera";
import Palette from "@material-ui/icons/Palette";
import Favorite from "@material-ui/icons/Favorite";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import NavPills from "components/NavPills/NavPills.js";
import Parallax from "components/Parallax/Parallax.js";
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import SubIcon from '@material-ui/icons/Subscriptions';

import profile from "assets/img/team/Guillaume-Palacios-200x200.jpg";


import thermalthreshold from "assets/img/thermalthreshold.png";
import brainactivity from "assets/img/brainactivity.png";


import styles from "assets/jss/material-kit-react/views/blogPost.js";

const useStyles = makeStyles(styles);

export default function SleepAndPain2(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);
  return (
    <div>
      <Header
        color="transparent"
        brand=" BLOG"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "darkPK"
        }}
        {...rest}
      />
      <Parallax small filter image={require("assets/img/sleep2.jpg")} />
      <div className={classNames(classes.main)}>
        <div>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={6}>
                <div className={classes.profile}>
                  <div>
                    <img src={profile} alt="..." className={imageClasses} />
                  </div>
                  <div className={classes.name}>
                    <h3 className={classes.title}>DOULEUR & SOMMEIL — 2è PARTIE</h3>
                    <h6>Guillaume Palacios, PhD</h6>
                    <h6>co-fondateur de PainkillAR</h6>
                    {/* <Button justIcon link className={classes.margin5}>
                      <i className={"fab fa-twitter"} />
                    </Button>
                    <Button justIcon link className={classes.margin5}>
                      <i className={"fab fa-instagram"} />
                    </Button>
                    <Button justIcon link className={classes.margin5}>
                      <i className={"fab fa-facebook"} />
                    </Button> */}
                  </div>
                </div>
              </GridItem>
            </GridContainer>
            <div className={classes.abstract}>
            2ème Partie de notre article consacré aux interactions entre Douleur et Sommeil. Après une 1ère Partie plus théorique, on donne ici des “tips” pour mieux dormir malgré la douleur.            </div>

<div className={classes.text}>
Chers followers de PainkillAR, ceci est le 2è volet d’un sujet qui nous avons ouvert sur la thématique <b>Douleur & Sommeil</b>. Si vous n’avez pas lu la 1ère Partie, je vous invite à le faire ici : <b><a href="https://www.painkillar.com/blog/sleep-and-pain">DOULEUR & SOMMEIL — 1ère PARTIE</a></b>
</div>

<div className={classes.text}>
Dans la 1ère Partie de <b>Douleur & Sommeil</b>, nous abordions les causes des problèmes de sommeil chez les douloureux chroniques et présentions les récentes découvertes en neurosciences pouvant expliquer en quoi la douleur chronique a un impact sur la qualité de sommeil et que réciproquement, la privation de sommeil exacerbe la sensibilité à la douleur.
</div>

<div className={classes.text}>
<b>Dans cette 2è Partie, nous allons parler de choses plus pratiques</b> et vous donnez quelques “tips” (conseils) spécifiques pour des meilleures nuits quand on est douloureux chronique.
</div>

            <h3 className={classes.sectionHeader}>Les classiques</h3>
            <div className={classes.text}>
  <ul>
  <li>
  Si ce n’est pas déjà le cas, <b>investir dans une bonne literie</b> adaptée à votre morphologie. Quand on voit les dégâts qu’un manque de sommeil occasionne sur l’organisme (voir la 1ère Partie de cet article), mieux vaut casser un peu sa tirelire et acheter un bon matelas et un sommier qui maintient bien le corps.
    </li>
<li>
Veiller à ce que <b>le noir le plus complet</b> soit fait dans votre chambre. Si besoin installer des rideaux épais (personnellement j’ai mis dans ma chambre des rideaux phoniques aussi très utiles pour réduire le bruit venant de la rue si vous vivez en ville).
</li>
<li><b>Dormir dans une pièce à 18oC</b>. Je sais pas vous, mais moi j’ai eu du mal à dormir pendant l’épisode de canicule.</li>
<li>Faire de sa chambre un lieu épuré et minimaliste uniquement dédié au sommeil. <b>Supprimer toutes les distractions de votre chambre.</b> Surtout pas de télé dans la chambre (un crime !), pas d’iPad ni de smartphone. Les lumières bleues des écrans perturbent le cycle de la mélatonine, hormone régulatrice du cycle circadien.</li>
<li>De manière générale, <b>éviter toute stimulation physique ou intellectuelle 30 min avant de vous endormir</b>. Pas de social media. Lire le chapitre d’un livre peut aider, mais pas un livre à suspens comme le dernier Joël Dicker. Préférer un texte chiant comme Cosmos de Michel Onfray ;-)</li>
<li><b>Dîner léger et tôt.</b></li>
<li><b>Pas de caféine, pas de théine, pas d’alcool, pas de drogues.</b></li>
<li><b>Très important : se coucher systématiquement tous les soirs à la même heure.</b> Moi c’est à minuit tous les jours. Vous, peut-être 22 ou 23h voire 1h du matin. Et ce même le week end. À terme, vous allez re-programmer votre horloge interne pour être fatigué.e à heure fixe et vous n’aurez même plus besoin de réveil.</li>
  </ul>
  <b>Essayez pendant 30 jours, sans exception (c’est là le secret !).</b> Vous serez bluffé.e par le résultat.
</div>

<h3 className={classes.sectionHeader}>Les conseils spécifiques aux patients chroniques</h3>
            <div className={classes.text}>
  <ul>
  <li>
  Privilégier la <b>position allongé.e sur le côté</b> (en chien de fusil). Cela aide à limiter les épisodes d’apnée du sommeil et de reflux gastro-oesophagien.
    </li>
<li>
<b>Ne pas prendre d’antalgiques</b> en particulier opiacés (codéine, tramadol etc.) ou corticoïdes <b>juste avant de se coucher</b> et ainsi éviter les “night sweats” (sueurs nocturnes).
</li>
<li>Calmer l’esprit et apaiser les douleurs avec des techniques non-médicamenteuses telles que la <b>méditation</b> (avec des applications comme Headspace par exemple) ou encore mieux : <b>une séance de relaxation en réalité virtuelle</b> avec l’application <b><a href="https://www.lindra.co">Lindra de PainkillAR</a></b>. A faire 30 min avant de dormir.
</li>
  </ul>
</div>

<div className={classes.text}>
J’espère que ces conseils vous seront utiles. À très bientôt et prenez soin de vous.
</div>


<div className={classes.text}>
Retrouvez les news de PainkillAR sur <b><a href="https://www.painkillar.com">https://www.painkillar.com</a></b> et rendez-vous sur <b><a href="https://www.lindra.co">https://www.lindra.co</a> </b>pour en savoir plus sur notre <b>application de réalité virtuelle pour la gestion du stress et de la douleur</b>.
</div>

<div className={classes.text}>
Guillaume Palacios, PhD
CEO de PainkillAR
</div>

            



            
            
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={8} className={classes.navWrapper}>
                        <GridContainer justify="center">
                          <GridItem xs={12} sm={12} md={6}>
                          <Button
                            target="_blank" 
                            href="https://twitter.com/intent/tweet?text=Histoire%20de%20PainkillAR%20Réalité%20Virtuelle%20Anti-Douleur&via=Painkill_AR&url=https%3A%2F%2Fpainkillar.com%2Fblog%2Fsommeil-et-douleur-part-2"
                            color="twitter">
        <i
          className={"fab fa-twitter"}
        />{" "}
        Partager sur Twitter
      </Button>
      </GridItem>
                          <GridItem xs={12} sm={12} md={6}>
                          <Button 
                          color="facebook"
                          target="_blank" 
                          href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fpainkillar.com%2Fblog%2Fsommeil-et-douleur-part-2&amp;src=sdkpreparse"
                          >
        <i
          className={" fab fa-facebook-square"}
        />{" "}
        Partager sur Facebook
      </Button>
                          </GridItem>
                        </GridContainer>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
