/*eslint-disable*/
import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
// react components for routing our app without refresh
import { Link } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";
import classNames from "classnames";


// @material-ui/icons
import { Apps, CloudDownload } from "@material-ui/icons";

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const classes = useStyles();
  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <Button
          href="/#Home"
          color="transparent"
          className={classes.navLink}
        >
          Accueil
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          href="/#DNA"
          color="transparent"
          className={classes.navLink}
        >
          Notre ADN
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          href="/#Products"
          color="transparent"
          className={classes.navLink}
        >
          Nos Produits
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          href="/#Team"
          color="transparent"
          className={classes.navLink}
        >
          L'Équipe
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          href="/#Partners"
          color="transparent"
          className={classes.navLink}
        >
          Partenaires
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          href="/#Press"
          color="transparent"
          className={classes.navLink}
        >
          Presse
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          href="/#Contact"
          color="transparent"
          className={classes.navLink}
        >
          Contactez-nous
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          href="/blog"
          color="transparent"
          className={classes.navLink}
        >
          Blog
        </Button>
      </ListItem>
      {/* <ListItem className={classes.listItem}>
        <Button
          href="http://medium.com/@painkillar"
          color="transparent"
          target="_blank"
          className={classes.navLink}
        >
        <i className={classes.socialIcons + " fab fa-medium"} />
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-twitter"
          title="Suivez-nous sur twitter"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            href="https://twitter.com/painkill_ar?lang=fr"
            target="_blank"
            color="transparent"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-twitter"} />
          </Button>
        </Tooltip>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-facebook"
          title="Suivez-nous sur facebook"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            href="https://www.facebook.com/painkillar.io/"
            target="_blank"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-facebook"} />
          </Button>
        </Tooltip>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-tooltip"
          title="Suivez-nous sur instagram"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            href="https://www.instagram.com/painkillar_therapy/?hl=fr"
            target="_blank"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-instagram"} />
          </Button>
        </Tooltip>
      </ListItem> */}
    </List>
  );
}
