/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// material-ui core components
import { List, ListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";
import Button from "components/CustomButtons/Button.js";
import Tooltip from "@material-ui/core/Tooltip";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Hidden from '@material-ui/core/Hidden';
import Grid from "@material-ui/core/Grid";



import styles from "assets/jss/material-kit-react/components/footerStyle.js";
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  const { whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  return (
    <footer className={footerClasses}>
      <div className={classes.container}>
        <Hidden mdDown>
        <div className={classes.left}>
          <List className={classes.list}>
            <ListItem className={classes.inlineBlock}>
              <a
                href="/#Home"
                className={classes.block}
              >
                Accueil
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <a
                href="/#DNA"
                className={classes.block}
              >
                Notre ADN
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <a
                href="/#Products"
                className={classes.block}
              >
                Nos Produits
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <a
                href="/#Team"
                className={classes.block}
              >
                L'Équipe
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <a
                href="/#Partners"
                className={classes.block}
              >
                Nos Partenaires
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <a
                href="/#Press"
                className={classes.block}
              >
                Presse
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <a
                href="/#Contact"
                className={classes.block}
              >
                Contactez-nous
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <a
                href="/blog"
                className={classes.block}
              >
                Blog
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
                            <Tooltip
                id="instagram-medium"
                title="Lisez-nous sur Medium"
                placement={window.innerWidth > 959 ? "top" : "left"}
                classes={{ tooltip: classes.tooltip }}
              >
              <Button
                href="https://medium.com/@painkillar"
                className={classes.socialButton}
                color="transparent"
                target="_blank"
              >
              <i className={classes.socialIcons + " fab fa-medium"} />

              </Button>
              </Tooltip>


            </ListItem>
            <ListItem className={classes.inlineBlock}>
            {/*<Tooltip title="Delete">
              <IconButton aria-label="Delete">
                <DeleteIcon />
              </IconButton>
            </Tooltip>*/}
              <Tooltip
                id="instagram-twitter"
                title="Suivez-nous sur Twitter"
                placement={window.innerWidth > 959 ? "top" : "left"}
                classes={{ tooltip: classes.tooltip }}
              >
                <Button
                  href="https://twitter.com/painkill_ar?lang=fr"
                  target="_blank"
                  color="transparent"
                  className={classes.socialButton}
                >
                  <i className={classes.socialIcons + " fab fa-twitter"} />
                </Button>
              </Tooltip>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <Tooltip
                id="instagram-facebook"
                title="Suivez-nous sur Facebook"
                placement={window.innerWidth > 959 ? "top" : "left"}
                classes={{ tooltip: classes.tooltip }}
              >
                <Button
                  color="transparent"
                  href="https://www.facebook.com/painkillar.io/"
                  target="_blank"
                  className={classes.socialButton}
                >
                  <i className={classes.socialIcons + " fab fa-facebook"} />
                </Button>
              </Tooltip>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <Tooltip
                id="instagram-tooltip"
                title="Suivez-nous sur Instagram"
                placement={window.innerWidth > 959 ? "top" : "left"}
                classes={{ tooltip: classes.tooltip }}
              >
                <Button
                  color="transparent"
                  href="https://www.instagram.com/painkillar_therapy/?hl=fr"
                  target="_blank"
                  className={classes.socialButton}
                >
                  <i className={classes.socialIcons + " fab fa-instagram"} />
                </Button>
              </Tooltip>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <Tooltip
                id="youtube-tooltip"
                title="Regardez-nous sur YouTube"
                placement={window.innerWidth > 959 ? "top" : "left"}
                classes={{ tooltip: classes.tooltip }}
              >
                <Button
                  color="transparent"
                  href="https://www.youtube.com/channel/UCwzpbSypWtAzxd-FH37GNWg"
                  target="_blank"
                  className={classes.socialButton}
                >
                  <i className={classes.socialIcons + " fab fa-youtube"} />
                </Button>
              </Tooltip>
            </ListItem>
          </List>
        </div>

        <div className={classes.right}>
          &copy; {1900 + new Date().getYear()} {" "}
          <a
            href="https://www.painkillar.com"
            className={aClasses}
            target="_blank"
          >
            PainkillAR
          </a>{" "}

        </div>
        </Hidden>
        <Hidden lgUp>
          <Grid container>
            <Grid item xs={12}>
            <List className={classes.list}>
            <ListItem className={classes.inlineBlock}>
              <a
                href="/#Home"
                className={classes.block}
              >
                Accueil
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <a
                href="/#DNA"
                className={classes.block}
              >
                Notre ADN
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <a
                href="/#Produits"
                className={classes.block}
              >
                Nos Produits
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <a
                href="/#Team"
                className={classes.block}
              >
                L'Équipe
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <a
                href="/#Partners"
                className={classes.block}
              >
                Nos Partenaires
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <a
                href="/#Press"
                className={classes.block}
              >
                Presse
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <a
                href="/#Contact"
                className={classes.block}
              >
                Contactez-nous
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <a
                href="/blog"
                className={classes.block}
              >
                Blog
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
            <Tooltip
                id="instagram-twitter"
                title="Lisez-nous sur Medium"
                placement={window.innerWidth > 959 ? "top" : "left"}
                classes={{ tooltip: classes.tooltip }}
              >
              <Button
                href="http://medium.com/@painkillar"
                className={classes.socialButton}
                target="_blank"
                color="transparent"
              >
              <i className={classes.socialIcons + " fab fa-medium"} />

              </Button>
              </Tooltip>
            </ListItem>
            </List>
            </Grid>
            <Grid item xs={12}>
            <ListItem className={classes.inlineBlock}>
            {/*<Tooltip title="Delete">
              <IconButton aria-label="Delete">
                <DeleteIcon />
              </IconButton>
            </Tooltip>*/}
              <Tooltip
                id="instagram-twitter"
                title="Suivez-nous sur Twitter"
                placement={window.innerWidth > 959 ? "top" : "left"}
                classes={{ tooltip: classes.tooltip }}
              >
                <Button
                  href="https://twitter.com/painkill_ar?lang=fr"
                  target="_blank"
                  color="transparent"
                  className={classes.socialButton}
                >
                  <i className={classes.socialIcons + " fab fa-twitter"} />
                </Button>
              </Tooltip>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <Tooltip
                id="instagram-facebook"
                title="Suivez-nous sur Facebook"
                placement={window.innerWidth > 959 ? "top" : "left"}
                classes={{ tooltip: classes.tooltip }}
              >
                <Button
                  color="transparent"
                  href="https://www.facebook.com/painkillar.io/"
                  target="_blank"
                  className={classes.socialButton}
                >
                  <i className={classes.socialIcons + " fab fa-facebook"} />
                </Button>
              </Tooltip>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <Tooltip
                id="instagram-tooltip"
                title="Suivez-nous sur Instagram"
                placement={window.innerWidth > 959 ? "top" : "left"}
                classes={{ tooltip: classes.tooltip }}
              >
                <Button
                  color="transparent"
                  href="https://www.instagram.com/painkillar_therapy/?hl=fr"
                  target="_blank"
                  className={classes.socialButton}
                >
                  <i className={classes.socialIcons + " fab fa-instagram"} />
                </Button>
              </Tooltip>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <Tooltip
                id="youtube-tooltip"
                title="Regardez-nous sur YouTube"
                placement={window.innerWidth > 959 ? "top" : "left"}
                classes={{ tooltip: classes.tooltip }}
              >
                <Button
                  color="transparent"
                  href="https://www.instagram.com/painkillar_therapy/?hl=fr"
                  target="_blank"
                  className={classes.socialButton}
                >
                  <i className={classes.socialIcons + " fab fa-youtube"} />
                </Button>
              </Tooltip>
            </ListItem>
            </Grid>
            <Grid xs={12}>
            <div className={classes.center}>
              &copy; {1900 + new Date().getYear()} {" "}
              <a
                href="https://www.painkillar.com"
                className={aClasses}
                target="_blank"
              >
                PainkillAR
              </a>{" "}
            </div>
            </Grid>
          </Grid>
        </Hidden>
        </div>

    </footer>
  );
}

Footer.propTypes = {
  whiteFont: PropTypes.bool
};
