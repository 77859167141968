import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/testimonialsStyle.js";

const useStyles = makeStyles(styles);

export default function TestimonialsSection() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem cs={12} sm={12} md={8}>
          <h2 className={classes.title}>Témoignages</h2>
          <h4 className={classes.description}>
            "L’application Lindra de PainkillAR m'a permis, en totale immersion dans cet environnement,
            de cheminer sur un parcours virtuel, et ainsi de faire abstraction de tout ce qui venait parasiter
             mon cerveau dans la perception de mes douleurs." <br/>- Nathalie (60).
          </h4>
        </GridItem>

        <GridItem cs={12} sm={12} md={8}>
          <h4 className={classes.description}>
            "Je viens d'expérimenter l'application Lindra de PainkillAR.
            J'en suis enchantée.
            On est transportés dans un autre monde. 
            Tout est bien : la musique, l'esthétisme.
            En ce qui me concerne je me suis je me suis concentrée sur la boule et me suis laissée porter
            dans cet univers ouaté qui fait du bien et qui apaise." <br/>- Colette (92).
          </h4>
        </GridItem>

        <GridItem cs={12} sm={12} md={8}>
          <h4 className={classes.description}>
            "Je viens de faire une séance de Lindra. Ça fait du bien, on oublie tout. J'ai même oublié ma douleur qui était présente
            sans être non plus très forte. Idéal avant de s'endormir." <br/>- Christiane (60).
          </h4>
        </GridItem>
        
      </GridContainer>
    </div>
  );
}
