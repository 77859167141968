import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/teamStyle.js";


import CardWithImage from "views/Components/CardWithImage";

import pitie from "assets/img/clients/pitie-salpetriere400.png";
import amiens from "assets/img/clients/CHU-Amiens.jpg";
import telecom from "assets/img/clients/telecom.jpg";
import bpi from "assets/img/clients/bpi.png";

import andar from "assets/img/clients/andar200.png";
import defhi from "assets/img/clients/defhi91.jpg";
import fibromyalgies from "assets/img/clients/fibromyalgies.fr.png";
import hif from "assets/img/clients/hif60.jpg";
import ltls from "assets/img/clients/ltls.jpg";
import emmaeusconnect from "assets/img/clients/emmaus-connect.jpg";
import sedinfr from "assets/img/clients/Sed_In_France_sans_marges.png";
import sdtb from "assets/img/clients/SDTB.png";

const useStyles = makeStyles(styles);

export default function PartnersSection() {
  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  return (
    <div id="Partners" className={classes.section}>
      <h2 className={classes.title}>Nos Partenaires</h2>
      <h3 className={classes.title}>Nos partenaires scientifiques et opérationnels</h3>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={3}>
            <CardWithImage 
              image={pitie} 
              partner="CETD - Pitié-Salpêtrière" 
              content="Le Centre d'Évaluation et de Traitement de la Douleur de la Pitié-Salpêtrière à Paris est notre partenaire scientifique. Avec leurs équipes médicales, nous testons et améliorons nos applications de réalité virtuelle anti-douleur."
              website="http://pitiesalpetriere.aphp.fr/evaluation-et-traitement-de-la-douleur/"/>
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <CardWithImage image={amiens} 
              partner="CETD - CHU Amiens"
              content="Les équipes du Pr. Serra à Amiens suivent et réorientent nos travaux de R&D depuis 2018. Leur grande expertise en thérapies non-médicament- euses nous aident à développer des solutions pertinentes et utiles aux patients."
              website="http://www.chu-amiens.fr/patients-visiteurs/nos-engagements/non-a-la-douleur-le-chu-damiens-sengage-a-vous-soulager/"
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <CardWithImage 
              image={telecom} 
              width="100px" 
              partner="TELECOM ParisTech Entrepreneurs"
              content="PainkillAR est incubée au sein de TELECOM ParisTech. La qualité de leurs conseils, de leur écoute et leur expertise des startups technologiques sont quasiment sans égales dans l'écosystème parisien."
              website="http://paristech-entrepreneurs.fr/"/>
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <CardWithImage 
              image={bpi} 
              partner="Bpifrance"
              content="Acteur incontournable de l'aide aux entreprises françaises, Bpifrance nous accompagne dans le financement 
              de notre programme d'innovation via la subvention PIA (Paris Innovation Amorçage) d'un montant de 30k&euro;."
              website="https://www.bpifrance.fr/"
            />
          </GridItem>
        </GridContainer>
      </div>
      <h3 className={classes.title}>Nos associations partenaires et celles que nous soutenons</h3>
      <div>
      <GridContainer>
          <GridItem xs={12} sm={12} md={3}>
            <CardWithImage 
              image={andar} 
              width="100px" 
              partner="Andar"
              content="Notre collaboration avec l'Association Nationale de Défense contre l'Arthrite Rhumatoïde date de 2018. L'ANDAR est la principale association française de patients souffrant de polyarthrite."
              website="http://www.polyarthrite-andar.com/"/>
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <CardWithImage 
              image={defhi} 
              width="100px" 
              partner="DEFHI91"
              content="L'assocition basée à Massy (91) Défense Entraide Fibromyalgies Handicaps Incompris agit pour la reconnaissance de pathologies telles que la fibromyalgie ou la maladie de Lyme.
              "
              website="https://defhi91.fr/"/>
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <CardWithImage 
              image={emmaeusconnect} 
              width="100px" 
              partner="Emmaüs Connect"
              content="Emmaüs Connect travaille à la réduction de la fracture numérique en France. C'est une mission
              que soutient PainkillAR, nous-même engagés dans la democratisation de la technologie au service des malades chroniques."
              website="https://emmaus-connect.org/"/>
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <CardWithImage 
              image={fibromyalgies} 
              partner="fibromyalgies.fr"
              content="fibromyalgies.fr est l'une des nombreuses associations françaises qui travaille sur la reconnaissance de la fibromyalgie comme handicap.
              Ses adhérents nous soutiennent de longue date."
              website="https://fibromyalgies.fr/"/>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={3}>
            <CardWithImage 
              image={hif} 
              width="100px" 
              partner="HIF60"
              content="Association de l'Oise, HIF 60
              milite pour la reconnaissance des handicaps invisibles, de la fibromyalgie au SED (Syndrome d'Ehlers Danlos). Très proche de HIF, PainkillAR compte 
              de nombreux utilisateurs parmi leurs adhérents."
              website="https://asso-hif.fr/"/>
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <CardWithImage 
              image={ltls} 
              width="100px" 
              partner="LTLS"
              content="Incarnée par la très dynamique Sylvie (Centre des Massues à Lyon), l'association La Lombalgie Tu Lui Souris agit pour la prise en charge de la lombalgie chronique, 1ère cause de
              douleur en France."
              website="https://www.facebook.com/sylvie.bouchard.378537"/>
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <CardWithImage 
              image={sedinfr} 
              partner="SED'in France"
              content="PainkillAR est très sensibilisée au problème du Syndrome d'Ehlers Danlos. Nous soutenons l'association
              SED'in France très active sur le sujet qui offre des conseils très documentés aux patients."
              website="https://sedinfrance.org/"/>
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <CardWithImage 
              image={sdtb} 
              width="100px" 
              partner="SDTB Notre Bataille"
              content="SDTB Notre Bataille est une association d'aide aux malades du Syndrome du Défilé thoraco-brachial. Rencontrés lors d'un salon à Massy, les adhérents de SDTB Notre Bataille, sont depuis le début impliqués dans le projet PainkillAR. 
              "
              website ="https://www.infos-sdtb.fr/"/>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
