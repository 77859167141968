import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Camera from "@material-ui/icons/Camera";
import Palette from "@material-ui/icons/Palette";
import Favorite from "@material-ui/icons/Favorite";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import NavPills from "components/NavPills/NavPills.js";
import Parallax from "components/Parallax/Parallax.js";
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import SubIcon from '@material-ui/icons/Subscriptions';

import profile from "assets/img/team/Guillaume-Palacios-200x200.jpg";

import studio1 from "assets/img/examples/studio-1.jpg";
import studio2 from "assets/img/examples/studio-2.jpg";
import studio3 from "assets/img/examples/studio-3.jpg";
import studio4 from "assets/img/examples/studio-4.jpg";
import studio5 from "assets/img/examples/studio-5.jpg";

import patient from "assets/img/patient-painkillar.jpg";
import hospital from "assets/img/st-antoine.jpeg";
import meds from "assets/img/meds.jpg"
import screenshot from "assets/img/screenshotfused2.png";
import hypnotize from "assets/img/hypnotize.jpg";
import painkillarLogo from "assets/img/LOGO-CARRE-PAINKILLAR.jpg";

import work1 from "assets/img/examples/olu-eletu.jpg";
import work2 from "assets/img/examples/clem-onojeghuo.jpg";
import work3 from "assets/img/examples/cynthia-del-rio.jpg";
import work4 from "assets/img/examples/mariya-georgieva.jpg";
import work5 from "assets/img/examples/clem-onojegaw.jpg";

import styles from "assets/jss/material-kit-react/views/blogPost.js";

const useStyles = makeStyles(styles);

export default function WhyPainkillAR(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);
  return (
    <div>
      <Header
        color="transparent"
        brand=" BLOG"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "darkPK"
        }}
        {...rest}
      />
      <Parallax small filter image={require("assets/img/aaron-blanco-tejedor-VBe9zj-JHBs-unsplash.jpg")} />
      <div className={classNames(classes.main)}>
        <div>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={6}>
                <div className={classes.profile}>
                  <div>
                    <img src={profile} alt="..." className={imageClasses} />
                  </div>
                  <div className={classes.name}>
                    <h3 className={classes.title}>POURQUOI ON A CRÉÉ PAINKILLAR</h3>
                    <h6>Guillaume Palacios, PhD</h6>
                    <h6>co-fondateur de PainkillAR</h6>
                    {/* <Button justIcon link className={classes.margin5}>
                      <i className={"fab fa-twitter"} />
                    </Button>
                    <Button justIcon link className={classes.margin5}>
                      <i className={"fab fa-instagram"} />
                    </Button>
                    <Button justIcon link className={classes.margin5}>
                      <i className={"fab fa-facebook"} />
                    </Button> */}
                  </div>
                </div>
              </GridItem>
            </GridContainer>
            <div className={classes.abstract}>
              Vous êtes nombreux à nous demander comment nous est venue l'idée de PainkillAR et de Lindra.
              Dans cet article je vous raconte la génèse de la société de l'idée à la création.
            </div>

            <h3 className={classes.sectionHeader}>La douleur : un problème personnel</h3>


            <div className={classes.text}>
                

C'est peut-être cliché mais l'aventure <b>PainkillAR est née d'un problème personnel</b>.
Hiver 2014 : Je participe à la manifestation monstre en soutien aux victimes de Charlie Hebdo et de l'Hyper Casher.
Des centaines de milliers de personnes de tous horizons avancent péniblement, pied à pied, sur le boulevard Beaumarchais dans un froid glacial.
Quelques jours plus tard, je me réveille avec une vive douleur au niveau de l'oreille et de la face côté droit.
En me regardant dans la glace, je constate que mon regard est figé dans un rictus
asymétrique presque comique. La partie droite de mon visage est totalement bloquée et je n'arrive pas à fermer la paupière non plus.
Je me doute de ce qui se passe : paralysie faciale. Je vais voir mon généraliste, qui a la gentillesse de me prendre avant l'ouverture officielle du cabinet,
et qui confirme mon intuition. C'est sans doute une paralysie faciale a frigore, c'est à dire d'origine virale. Au passage, dans le contexte actuel d’épidémie de Covid-19, je me permets de rappeler que les grands rassemblements sont de véritables nids à virus.
<br/>
Par précaution, mon médecin me conseille d'aller à l'Hôpital Saint-Antoine à Paris pour passer une IRM et vérifier que la paralysie est bien périphérique (donc bénigne)
et non centrale, ce qui voudrait dire qu'elle est dûe à un problème neurologique plus préoccupant.
Le service de Saint-Antoine aux Urgences est d'une lenteur infinie. Je passe 8h sur place sans boire ni manger. Mais je suis vu par une équipe d'hommes 
et de femmes extrêmement compétents et à l'écoute. Des infirmières et des aides-soignants, des médecins, toutes et tous dévoués et qui font le maximum pour s'occuper
de grand nombre de patients qui engorgent le service.
<br/>
J'ai pris des anti-viraux et de la cortisone à haute dose. Avec le temps, la paralysie a fini par se résorber, même si je garde quelques séquelles, en témoigne
mon léger regard de travers sur mes photos et dans mes interviews ;-).

Mais <b>la douleur s'est installée, de façon lente et pernicieuse</b>. Des sensations de décharges électriques autour de l'oreille et de la mâchoire, comme des brûlures, et une sensibilité aux bruits accrue (hyperacousie douloureuse). J’ai fait l’erreur de ne pas prendre au sérieux ces douleurs, les mettant sur le compte de la paralysie et de ses symptômes normaux. Le problème c’est que si elle n’est pas traitée rapidement, la douleur s’installe et devient chronique. Elle dure des mois, des années, toute la vie même. Et il faut apprendre à vivre avec. Pas si simple.
</div>

<h3 className={classes.sectionHeader}>Le Centre d’Évaluation et de Traitement de la Douleur</h3>

<div className={classes.text}>

Après des mois de souffrance, un ami médecin m’a finalement écrit un courrier, à l’ancienne ;-), afin d’obtenir une consultation au Centre d’Évaluation et de Traitement de la Douleur (CETD) de Saint-Antoine à Paris. <b>Les CETD sont des unités pluridisciplinaires spécialisées dans la prise en charge de la douleur</b>. 
Elles sont rattachées à des hôpitaux universitaires regroupant des médecins algologues, des kinésithérapeutes, des psychologues, etc. d’une qualité remarquable. 
Mais, comme la crise de l’épidémie de Covid-19 que nous traversons le révèle, les hôpitaux sont saturés et sous-dotés. <b>Il m’a fallu 6 mois pour obtenir un rendez-vous</b>. C’est très long, d’autant plus qu’en six mois, la douleur commence à s’imprimer dans les circuits neuronaux un peu comme un fichier sur un disque dur. 
Le problème, c’est que pour “enlever” cette information douloureuse, il ne suffit pas d’appuyer sur SUPPRIMER ou de la “glisser dans la corbeille”. 
Les circuits neuronaux obéissent à une mécanique connue sous le nom de plasticité de Hebb. Plus des groupes de neurones sont sollicités, plus les connexions entre ces derniers sont renforcés. 
C’est un processus très utile pour la mémoire et la consolidation des acquis tels que le calcul ou l’écriture. 
Mais, comme les études tendent à le montrer, cela contribue aussi à une perturbation de la connexion neuronale chez les patients souffrant de douleur chronique. 
</div>
<GridContainer justify="center">
              <GridItem xs={12} sm={12} md={8} className={classes.navWrapper}>
                        <GridContainer justify="center">
                          <GridItem xs={12} sm={12} md={8}>
                            <img
                              alt="..."
                              src={hospital}
                              className={navImageClasses}
                            />
                          </GridItem>
                        </GridContainer>
              </GridItem>
            </GridContainer>

            <h3 className={classes.sectionHeader}>Trop de médicaments</h3>
<div className={classes.text}>

La neurologue du CETD de St-Antoine m’a prescrit une tonne de médicaments qui se sont avérés peu efficaces et avec beaucoup d’effets secondaires très invalidants : nausée, perte de mémoire et troubles du comportement. 
J'ai pris du Lyrica, des opiacés, des neuroleptiques, etc. Ma pharmacienne me voyait arriver au comptoir de son officine avec une ordonnance longue comme le bras. Et toujours la mine aussi déconfite.
On m’a rassuré en me disant que <b>les médicaments ne marchaient que pour 55% des patients (à peine), en particulier dans le cas des douleurs neuropathiques</b>. 
</div>
<GridContainer justify="center">
              <GridItem xs={12} sm={12} md={8} className={classes.navWrapper}>
                        <GridContainer justify="center">
                          <GridItem xs={12} sm={12} md={8}>
                            <img
                              alt="..."
                              src={meds}
                              className={navImageClasses}
                            />
                          </GridItem>
                        </GridContainer>
              </GridItem>
            </GridContainer>
            <h3 className={classes.sectionHeader}>Les très chères thérapies alternatives</h3>
<div className={classes.text}>

De là, j’ai été orienté vers l’acupuncture et l’hypnose. A priori très sceptique quant à l’efficacité de l’hypnose thérapeutique, ma première séance a été comme une révélation, une sensation d’être comme dans du coton et, pendant quelques minutes, l’impression de ne plus avoir mal. 
Mais il est difficile de retrouver le même niveau d’immersion chez soi, en auto-hypnose. 
<br/>
<b>Dans leur ensemble, les thérapies non-médicamenteuses telles que les TCC (Thérapies Comportementales et Cognitives) ainsi que l’hypnose sont efficaces. Cependant, elles ne sont pas accessibles en dehors des zones urbaines et elles restent coûteuses : si l’on ne veut pas attendre 6 mois pour un rendez-vous en CETD, il faut prévoir 80EUR la séance dans le privé, non remboursée</b>.
</div>
<GridContainer justify="center">
              <GridItem xs={12} sm={12} md={8} className={classes.navWrapper}>
                        <GridContainer justify="center">
                          <GridItem xs={12} sm={12} md={8}>
                            <img
                              alt="..."
                              src={hypnotize}
                              className={navImageClasses}
                            />
                          </GridItem>
                        </GridContainer>
              </GridItem>
            </GridContainer>
            <h3 className={classes.sectionHeader}>Genèse de PainkillAR</h3>
<div className={classes.text}>
<b>Ne serait-il pas génial de pouvoir simuler ces séances d’hypnose si bénéfiques et de pouvoir les faire à l’envi depuis son salon ?</b> 
<br/>L'avènement des technologies immersives grand public -en particulier la réalité virtuelle (VR) mobile et la réalité augmentée- rendait cela en théorie possible quand nous avons commencé fin 2018. Et en effet, la réalité virtuelle commençait à l’époque à être testée en laboratoire et dans certains centres hôpistaliers-universitaires aux Etats-Unis, en Europe, au Canada et en Israël (<a href="/lindra-blog/vr-pain">voir notre article précédent</a>). 
Mais <b>aucun produit accessible aux communs des mortels</b>. Or les patients, et j’en sais quelque chose, ont essentiellement mal chez eux ou sur leur lieu de travail, pas forcément une fois en consultation à l’hôpital (le fameux effet Placebo “Viens voir le Docteur”).
<br/>J’ai rencontré Irshad alors qu’il revenait de deux ans à l’université de Keio au Japon où il avait travaillé sur des sujets de réalité virtuelle et de Computer Vision (Vision par ordinateur) appliquée à la chirurgie du cancer du sein. Ensemble, nous partagions la conviction que les technologies immersives allaient permettre de démocratiser l’hypnose analgésique et la méditation pleine conscience. Des techniques utilisées en hypnose telles que la visualisation et l’induction sont ainsi plus facilement accessibles. Nous avons ainsi créé PainkillAR.
</div>
<GridContainer justify="center">
              <GridItem xs={12} sm={12} md={8} className={classes.navWrapper}>
                        <GridContainer justify="center">
                          <GridItem xs={12} sm={12} md={8}>
                            <img
                              alt="..."
                              src={painkillarLogo}
                              className={navImageClasses}
                            />
                          </GridItem>
                        </GridContainer>
              </GridItem>
            </GridContainer>
            <h3 className={classes.sectionHeader}>Notre raison d'être</h3>
<div className={classes.text}>

<b>Notre mission chez PainkillAR est de développer une solution non médicamenteuse de gestion de la douleur chronique qui s’appuie sur la technologie de VR mobile</b>. Notre but est de transposer les recherches et les expériences de VR thérapeutiques aujourd’hui cantonnées dans les laboratoires et les grands centres hospitaliers universitaires et de les traduire dans <b>des produits accessibles aux patients chez eux</b>. 
<br/>
<b>Car la douleur arrive n’importe où et n’importe quand</b>.
<br/>
<br/>
Votre avis et votre expérience nous intéressent.
Venez discuter avec moi par email <b>guillaume@painkillar.io</b> ou testez notre application Lindra.
            </div>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={8} className={classes.navWrapper}>
                        <GridContainer justify="center">
                          <GridItem xs={12} sm={12} md={8}>
                          <Button
          href="https://www.lindra.co/"
          color="rose"
          target="_blank"
        >
          <SubIcon/>Je m'inscris
        </Button>
                          </GridItem>
                        </GridContainer>
              </GridItem>
            </GridContainer>


            
            
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={8} className={classes.navWrapper}>
                        <GridContainer justify="center">
                          <GridItem xs={12} sm={12} md={6}>
                          <Button
                            target="_blank" 
                            href="https://twitter.com/intent/tweet?text=Histoire%20de%20PainkillAR%20Réalité%20Virtuelle%20Anti-Douleur&via=Painkill_AR&url=https%3A%2F%2Fpainkillar.com%2Fblog%2Fwhy-painkillar"
                            color="twitter">
        <i
          className={"fab fa-twitter"}
        />{" "}
        Partager sur Twitter
      </Button>
      </GridItem>
                          <GridItem xs={12} sm={12} md={6}>
                          <Button 
                          color="facebook"
                          target="_blank" 
                          href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fpainkillar.com%2Fblog%2Fwhy-painkillar&amp;src=sdkpreparse"
                          >
        <i
          className={" fab fa-facebook-square"}
        />{" "}
        Partager sur Facebook
      </Button>
                          </GridItem>
                        </GridContainer>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
