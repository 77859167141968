import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Camera from "@material-ui/icons/Camera";
import Palette from "@material-ui/icons/Palette";
import Favorite from "@material-ui/icons/Favorite";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import NavPills from "components/NavPills/NavPills.js";
import Parallax from "components/Parallax/Parallax.js";

import profile from "assets/img/team/Guillaume-Palacios-200x200.jpg";

import studio1 from "assets/img/examples/studio-1.jpg";
import studio2 from "assets/img/examples/studio-2.jpg";
import studio3 from "assets/img/examples/studio-3.jpg";
import studio4 from "assets/img/examples/studio-4.jpg";
import studio5 from "assets/img/examples/studio-5.jpg";

import patient from "assets/img/patient-painkillar.jpg";
import screenshot from "assets/img/screenshotfused2.png";
import lindraGIF from "assets/img/FastLindraGIF.gif"

import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

import work1 from "assets/img/examples/olu-eletu.jpg";
import work2 from "assets/img/examples/clem-onojeghuo.jpg";
import work3 from "assets/img/examples/cynthia-del-rio.jpg";
import work4 from "assets/img/examples/mariya-georgieva.jpg";
import work5 from "assets/img/examples/clem-onojegaw.jpg";
import SubIcon from '@material-ui/icons/Subscriptions';

import styles from "assets/jss/material-kit-react/views/blogPost.js";

const useStyles = makeStyles(styles);

export default function WhatIsLindra(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);
  return (
    <div>
      <Header
        color="transparent"
        brand=" BLOG"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "darkPK"
        }}
        {...rest}
      />
      <Parallax small filter image={require("assets/img/FullLogo.png")} />
      <div className={classNames(classes.main)}>
        <div>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={6}>
                <div className={classes.profile}>
                  <div>
                    <img src={profile} alt="..." className={imageClasses} />
                  </div>
                  <div className={classes.name}>
                    <h3 className={classes.title}>LINDRA, C'EST QUOI ?</h3>
                    <h6>Guillaume Palacios, PhD</h6>
                    <h6>co-fondateur de PainkillAR</h6>
                    {/* <Button justIcon link className={classes.margin5}>
                      <i className={"fab fa-twitter"} />
                    </Button>
                    <Button justIcon link className={classes.margin5}>
                      <i className={"fab fa-instagram"} />
                    </Button>
                    <Button justIcon link className={classes.margin5}>
                      <i className={"fab fa-facebook"} />
                    </Button> */}
                  </div>
                </div>
              </GridItem>
            </GridContainer>
            <div className={classes.abstract}>
              Dans cet article, on vous explique comment marche Lindra, notre application de gestion de la douleur en réalité virtuelle.
            </div>

            <h3 className={classes.sectionHeader}>Pourquoi une application de réalité virtuelle ?</h3>
            <div className={classes.text}>
                

              On est tous pareils. <b>Quand on souffre de douleur ou d'anxiété chronique, on cherche le remède miracle</b>.
              On se tourne vers les médecins et les prescriptions. Puis, quand on se rend compte que les médicaments ont plus d'effets indésirables que de bénéfices,
              on essaie les thérapies alternatives, la sophrologie et la méditation. Et pour les plus desespérés d'entre nous, on finit par tenter le jus de papaye, les magnétiseurs, les gurus voire les sectes <span role="img" aria-label="smiley">😀</span>.
              Alors vous me direz, pourquoi une application de réalité virtuelle pour gérer mes douleurs ? Ça a l'air burlesque !
              <br/>
              Chez PainkillAR, on a cherché, on a lu, on a consulté des experts, des patients, des dirigeants d'associations, des psychiatres, des rhumatologues, des anesthésistes, des spécialites de l'hypnose, des sophrologues, des maîtres zen, des guérisseurs, des pratiquants de yoga et de reikki.
              On a regardé également du côté des technologies : TENS (stimulation électrique trans-cutanée), rTMS (stimulation magnétique trans-crânienne), des applications de suivi de patients, des chatbots, des applications de méditation et de bien-être.
              Toujours pas de solution idéale. Et puis, on a regardé du côté de la recherche. Et on est tombé sur les travaux américains en réalité virtuelle (VR). 
              <b> La réalité virtuelle permet de faire un travail très profond sur la psyche depuis son salon</b> (pour plus de détails lire notre <a href="/lindra-blog/vr-pain">article sur VR et douleur</a>). Il faut juste un smartphone et un casque à 20EUR.
            
            </div>
            
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={8} className={classes.navWrapper}>
                  <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={8}>
                      <img
                        alt="..."
                        src={patient}
                        className={navImageClasses}
                      />
                      Fig. 1 : Utilisatrice testant l'application Lindra de PainkillAR lors du salon VivaTech 2019. 
                      Ici, la jeune femme utilise la version de Lindra développée pour le casque de réalité virtuelle &copy;Oculus Go.
                    </GridItem>
                  </GridContainer>
              </GridItem>
            </GridContainer>

            <h3 className={classes.sectionHeader}>Rappel de notre mission</h3>
            <div className={classes.text}>

              <b>Notre job chez PainkillAR, c'est de développer une solution non médicamenteuse de gestion de la douleur chronique qui s’appuie sur la technologie de VR mobile. </b>
              Notre but est de transposer les recherches et les expériences de VR thérapeutiques aujourd’hui cantonnées dans les laboratoires et les grands centres hospitaliers universitaires et de les traduire dans des produits accessibles aux patients chez eux. 
              Car la douleur arrive n’importe où et n’importe quand.
            </div>
            
            <h3 className={classes.sectionHeader}>Lindra - la réalité virtuelle anti-douleur pour tous</h3>
            <div className={classes.text}>
              <b>Conçue pour un usage quotidien, notre dernière création, Lindra, est une application dite de “marche méditative”</b>.
              Elle propose des exercices évolutifs et s’adapte à l’évolution de chaque utilisateur. 
              Utilisée régulièrement, <b>Lindra a un effet apaisant et hypnotique qui permettra de mieux gérer la sensation de douleur</b>.
              Nous espérons aussi qu'une utilisation de Lindra sur le long terme, permettra de <b>réduire la consommation de médicaments</b>, et d’<b>offrir une autonomie aux patients</b> et ainsi désengorger le système de santé.

              <h5><b>Application Mobile</b></h5>
              Aujourd'hui, Lindra est disponible sur Android et iPhone en accès limité à une centaine d'utilisateurs triés sur le volet. Pour tout renseignement
              nous contacter par email à l'adresse <b>social@painkillar.io</b> ou s'inscrire en cliquant sur le lien ci-dessous :
            </div>
            
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={8} className={classes.navWrapper}>
                <GridContainer justify="center">
                  <GridItem xs={12} sm={12} md={8}>
                    <Button
                      href="https://www.lindra.co/"
                      color="rose"
                      target="_blank"
                    >
                      <SubIcon/> Je m'inscris
                    </Button>
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>

            <div className={classes.text}>

              <h5><b>Comment ça marche ?</b></h5>
              
              Une séance de réalité virtuelle thérapeutique avec Lindra se déroule ainsi : l’utilisateur lance son application sur son smartphone et glisse son téléphone dans un casque de VR mobile pour smartphone (Google Cardboard) ou standalone, c’est-à-dire qui fonctionne sans smartphone (Oculus Go) [Fig. 1]. L’utilisateur confortablement assis ou semi-allongé ajuste le casque de réalité virtuelle sur sa tête et apprécie pendant 3 à 10 minutes un voyage dans un paysage calme et relaxant parfois agrémenté d’exercices psychomoteurs ou cognitifs [Fig. 2].
              Les études que nous menons, en partenariat avec le CETD de la Pitié-Salpêtrière à Paris, montrent que, si une personne utilise la réalité virtuelle pour faire des séances d’hypnose ou de marche méditative, il est fort probable que les douleurs et leur retentissement dans la vie quotidienne se voient diminués.
            
            </div>

            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={8} className={classes.navWrapper}>
                <GridContainer justify="center">
                  <GridItem xs={12} sm={12} md={8}>
                    <img
                      alt="..."
                      src={lindraGIF}
                      className={navImageClasses}
                    />
                    Fig. 2 : Exemple d'expérience de marche méditative proposée dans Lindra. Le paysage s'enrichit en fonction du comportement de l'utilisateur.
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>

            <h3 className={classes.sectionHeader}>Rejoignez la communauté des utilisateurs de Lindra</h3>
            <div className={classes.text}>
              
              
              L'expérience de réalité virtuelle Lindra développée par PainkillAR est le fruit de mois de recherche et de développement. 
              Elle a été conçue pour offrir à toutes celles et ceux qui souffrent de douleur chronique de bénéficier des meilleures avancées dans le domaine du traitement de la douleur chronique.
              <br/>
              <b>Rejoignez dès aujourd'hui le mouvement des utilisateurs de Lindra et ensemble, développons de nouvelles solutions pour vaincre la douleur.</b>
           
            </div>
            
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={8} className={classes.navWrapper}>
                <GridContainer justify="center">
                  <GridItem xs={12} sm={12} md={8}>
                    <Button
                      href="https://www.lindra.co/"
                      color="rose"
                      target="_blank"
                    >
                      <SubIcon/> Je m'inscris
                    </Button>
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>

            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={8} className={classes.navWrapper}>
                <GridContainer justify="center">
                  <GridItem xs={12} sm={12} md={6}>
                    <Button
                      target="_blank" 
                      href="https://twitter.com/intent/tweet?text=Lindra%20Réalité%20Virtuelle%20Anti-Douleur&via=Painkill_AR&url=https%3A%2F%2Fpainkillar.com%2Fblog%2Fwhat-is-lindra"
                      color="twitter"
                      >
                        <i className={"fab fa-twitter"}/>{" "}Partager sur Twitter
                    </Button>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <Button 
                      color="facebook"
                      target="_blank" 
                      href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fpainkillar.com%2Fblog%2Fwhat-is-lindra&amp;src=sdkpreparse"
                    >
                      <i className={" fab fa-facebook-square"}/>{" "}Partager sur Facebook
                    </Button>
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
