import { title } from "assets/jss/material-kit-react.js";

const useStyle = {
  section: {
    padding: "70px 0",
    textAlign: "left"
  },
  title: {
    ...title,
    marginBottom: "1rem",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none",
    textAlign: "center !important",
    color: "#16182E !important"
  },
  description: {
    // color: "#999",
    color: "#3F4045",
    fontWeight: "400",
    marginLeft: "-30px",
    textAlign: "justify !important"
  },
  center: {
    float: "center !important",
    textAlign: "center !important"
  },
  cardTitle :{
    fontWeight: '400'
  },
  cardImage: {
  },
  alignItemsAndJustifyContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
};

export default useStyle;