
/*eslint-disable*/
import React from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import classNames from "classnames";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);


export default function SocialButtons(props) {
    const classes = useStyles();
    return (
        <GridContainer xs={12} sm={12} md={3} spacing={1}>
            <GridItem xs={2} sm={2} md={2}>
            <Tooltip
          id="instagram-twitter"
          title="Lisez-nous sur Medium"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
        <Button
          href="http://medium.com/@painkillar"
          color="transparent"
          target="_blank"
          className={classes.navLink}
        >
        <i className={classes.socialIcons + " fab fa-medium"} />
        </Button>
        </Tooltip>

      </GridItem>
      <GridItem xs={2} sm={2} md={2}>
        {/*<Tooltip title="Delete">
          <IconButton aria-label="Delete">
            <DeleteIcon />
          </IconButton>
        </Tooltip>*/}
        <Tooltip
          id="instagram-twitter"
          title="Suivez-nous sur Twitter"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            href="https://twitter.com/painkill_ar?lang=fr"
            target="_blank"
            color="transparent"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-twitter"} />
          </Button>
        </Tooltip>
      </GridItem>
      <GridItem xs={2} sm={2} md={2}>
        <Tooltip
          id="instagram-facebook"
          title="Suivez-nous sur Facebook"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            href="https://www.facebook.com/painkillar.io/"
            target="_blank"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-facebook"} />
          </Button>
        </Tooltip>
      </GridItem>
      <GridItem xs={2} sm={2} md={2}>
        <Tooltip
          id="instagram-tooltip"
          title="Suivez-nous sur Instagram"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            href="https://www.instagram.com/painkillar_therapy/?hl=fr"
            target="_blank"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-instagram"} />
          </Button>
        </Tooltip>
      </GridItem>
      <GridItem xs={2} sm={2} md={2}>
        <Tooltip
          id="instagram-tooltip"
          title="Regardez-nous sur YouTube"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            href="https://www.youtube.com/channel/UCwzpbSypWtAzxd-FH37GNWg"
            target="_blank"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-youtube"} />
          </Button>
        </Tooltip>
      </GridItem>
      </GridContainer>
    )
}
