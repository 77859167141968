import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Camera from "@material-ui/icons/Camera";
import Palette from "@material-ui/icons/Palette";
import Favorite from "@material-ui/icons/Favorite";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import NavPills from "components/NavPills/NavPills.js";
import Parallax from "components/Parallax/Parallax.js";
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import SubIcon from '@material-ui/icons/Subscriptions';

import profile from "assets/img/team/Guillaume-Palacios-200x200.jpg";

import studio1 from "assets/img/examples/studio-1.jpg";
import studio2 from "assets/img/examples/studio-2.jpg";
import studio3 from "assets/img/examples/studio-3.jpg";
import studio4 from "assets/img/examples/studio-4.jpg";
import studio5 from "assets/img/examples/studio-5.jpg";

import patient from "assets/img/patient-painkillar.jpg";
import screenshot from "assets/img/screenshotfused2.png";

import work1 from "assets/img/examples/olu-eletu.jpg";
import work2 from "assets/img/examples/clem-onojeghuo.jpg";
import work3 from "assets/img/examples/cynthia-del-rio.jpg";
import work4 from "assets/img/examples/mariya-georgieva.jpg";
import work5 from "assets/img/examples/clem-onojegaw.jpg";

import styles from "assets/jss/material-kit-react/views/blogPost.js";

const useStyles = makeStyles(styles);

export default function VRPain(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);
  return (
    <div>
      <Header
        color="transparent"
        brand=" BLOG"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "darkPK"
        }}
        {...rest}
      />
      <Parallax small filter image={require("assets/img/vr-girl.jpg")} />
      <div className={classNames(classes.main)}>
        <div>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={6}>
                <div className={classes.profile}>
                  <div>
                    <img src={profile} alt="..." className={imageClasses} />
                  </div>
                  <div className={classes.name}>
                    <h3 className={classes.title}>LA RÉALITÉ VIRTUELLE POUR LA GESTION DE LA DOULEUR</h3>
                    <h6>Guillaume Palacios, PhD</h6>
                    <h6>co-fondateur de PainkillAR</h6>
                  </div>
                </div>
              </GridItem>
            </GridContainer>
            <div className={classes.abstract}>
              Avec le rôle croissant de la réalité virtuelle dans les soins de santé, 
              la technologie pourrait offrir de nouvelles options de thérapie à distance efficaces 
              voire même une solution à la crise des opiacés.{" "}
            </div>

            <h3 className={classes.sectionHeader}>La réalité virtuelle comme outil thérapeutique</h3>
            <div className={classes.text}>
                
                

La réalité virtuelle investit de plus en plus le champ médical. 
De la formation des chirurgiens sur simulateur en réalité virtuelle [1]
aux indications thérapeutiques (phobie, anxiété, stress post-traumatique), la liste des applications est longue.
Dans cet article, nous allons nous intéresser tout particulièrement au rôle de la réalité virtuelle dans le traitement de la douleur.
<br/>Début des années 2000, Hunter Hoffmann (Université de Washington) met au point « Snow World », un monde virtuel enneigé dans 
lequel on plonge des grands brûlés. 

</div>
<GridContainer justify="center">
              <GridItem xs={12} sm={12} md={8} className={classes.navWrapper}>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/jNIqyyypojg" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen margin-left="auto" margin-right="auto" display="block"></iframe>

              </GridItem>
            </GridContainer>
            <div className={classes.text}>
          
Cette immersion dans un environnement glacial soulage les patients qui ressentent moins 
leurs brûlures et voient leurs douleurs baisser de moitié [2]. 
En 2017, l’équipe de Brennan Spiegel (Cedars-Sinai Los Angeles) teste la réalité virtuelle sur des patients en soins hospitaliers 
souffrant de tous types de douleurs. 
</div>

<GridContainer justify="center">
              <GridItem xs={12} sm={12} md={8} className={classes.navWrapper}>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/zpcNhnU-Aaw" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

              </GridItem>
            </GridContainer>
            <div className={classes.text}>

En plus de la baisse de la douleur ressentie, les résultats de cette étude [3] montrent une diminution de la consommation d’antalgiques. 
En pleine crise des opiacés aux Etats-Unis où 47 000 personnes sont décédées à cause d’une overdose liée à une consommation d'opiacés rien qu’en 2017, les travaux de l’équipe de Los Angeles sont largement relayés par les médias américains qui mettent en lumière l’utilisation de la réalité virtuelle dans la prise en charge de la douleur. 
<br/>
Au-delà de ces deux exemples, d’autres études au Canada et en Europe viennent confirmer ces résultats très prometteurs et ouvrent la voie vers l’adoption de la réalité virtuelle comme nouvelle thérapie non médicamenteuse pour le traitement de la douleur.
Les principaux intérêts de la réalité virtuelle sont : son accessibilité — tant au niveau du prix que de l’usage — et l’absence d’effets secondaires.

            </div>


            <h3 className={classes.sectionHeader}>Conclusion</h3>

<div className={classes.text}>
L’utilisation de la réalité virtuelle apporte plusieurs bénéfices dans la prise en charge des patients douloureux chroniques, en particulier celles et ceux souffrant de maladies chroniques et inflammatoires telles que la <b>polyarthrite rhumatoïde</b>, la <b>fibromyalgie</b> ou la <b>maladie de Crohn</b>. 
<h5><b>C’est une technique accessible</b></h5>
 
Pour discuter très régulièrement avec des personnes souffrant de douleur, nous savons que les crises ne préviennent pas. 
Elles surgissent à toute heure et à tout moment de la journée. La mise à disposition d’une application de réalité virtuelle disponible sur téléphone mobile serait une véritable béquille pour les personnes qui en souffrent.
<h5><b>C’est une technique sans effets secondaires ni dépendance</b></h5>
Pour celles et ceux qui ont de lourds traitements à suivre et dont les effets secondaires sont pénibles à vivre, trouver une solution qui permettrait de réduire la consommation de médicaments répond à une attente forte.
<br/>
<br/>

Testez dès aujourd’hui Lindra notre première application de relaxation et de gestion de la douleur.
<br/>

            </div>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={8} className={classes.navWrapper}>
                        <GridContainer justify="center">
                          <GridItem xs={12} sm={12} md={8}>
                          <Button
          href="https://www.lindra.co"
          color="rose"
          target="_blank"
        >
          <SubIcon/>Je m'inscris
        </Button>
                          </GridItem>
                        </GridContainer>
              </GridItem>
            </GridContainer>
            <h3 className={classes.sectionHeader}>Références et Liens</h3>

            <div className={classes.text}>
<ul>
<li>[1] <a href="https://usbeketrica.com/article/la-realite-virtuelle-nouvel-outil-chirurgical-indispensable">https://usbeketrica.com/article/la-realite-virtuelle-nouvel-outil-chirurgical-indispensable</a></li> 
<li>[2] Hoffman HG, Chambers GT, Meyer WJ 3rd, et al. <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4465767/">Virtual reality as an adjunctive non-pharmacologic analgesic for acute burn pain during medical procedures.</a> Ann Behav Med. 2011;41(2):183–191.</li>
<li>[3] Tashjian VC, Mosadeghi S, Howard AR, Lopez M, Dupuy T, Reid M, Martinez B, Ahmed S, Dailey F, Robbins K, Rosen B, Fuller G, Danovitch I, IsHak W, Spiegel B, <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5390112/">Virtual Reality for Management of Pain in Hospitalized Patients: Results of a Controlled Trial</a>, JMIR Ment Health 2017;4(1):e9</li>
</ul>
            </div>
            
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={8} className={classes.navWrapper}>
                        <GridContainer justify="center">
                          <GridItem xs={12} sm={12} md={6}>
                          <Button
                            target="_blank" 
                            href="https://twitter.com/intent/tweet?text=Réalité%20Virtuelle%20Anti-Douleur&via=Painkill_AR&url=https%3A%2F%2Fpainkillar.com%2Fblog%2Fvr-pain"
                            color="twitter">
        <i
          className={"fab fa-twitter"}
        />{" "}
        Partager sur Twitter
      </Button>
      </GridItem>
                          <GridItem xs={12} sm={12} md={6}>
                          <Button 
                          color="facebook"
                          target="_blank" 
                          href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fpainkillar.com%2Fblog%2Fvr-pain&amp;src=sdkpreparse"
                          >
        <i
          className={" fab fa-facebook-square"}
        />{" "}
        Partager sur Facebook
      </Button>
                          </GridItem>
                        </GridContainer>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
