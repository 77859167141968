import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(styles);

export default function Contact() {
  const classes = useStyles();
  return (
    <div id="Contact" className={classes.section}>
      <GridContainer justify="center">
        <GridItem cs={12} sm={12} md={8}>
          <h2 className={classes.title}>Contactez-nous</h2>
          <h4 className={classes.description}>
          <i className={classNames("material-icons",classes.socialIcon)} >home</i> Adresse :
PainkillAR SAS - TELECOM ParisTech Entrepreneurs - 9 rue Dareau 75014 Paris
            <br/><i className={classNames("material-icons",classes.socialIcon)} >email</i> Email : social@painkillar.io
            <br/><i className={classNames("material-icons",classes.socialIcon)} >phone</i> Tél. : +33 (0) 1 45 81 70 87
            <br/>
          </h4>
          <h3 className={classes.title}>Retrouvez-nous sur les Réseaux Sociaux</h3>
          <h4 className={classes.description}>
            Lisez notre Blog sur Medium <a
                href="https://medium.com/@painkillar"
                className={classes.block}
                target="_blank"
              >
              <i className={classes.socialIcons + " fab fa-medium"} />
              </a>
              <br/>
            Suivez-nous sur Twitter <a
                href="https://twitter.com/Painkill_AR"
                className={classes.block}
                target="_blank"
              >
              <i className={classes.socialIcons + " fab fa-twitter"} />
              </a>
              <br/>
            Likez notre page Facebook <a
                href="https://www.facebook.com/painkillar.io"
                className={classes.block}
                target="_blank"
              >
              <i className={classes.socialIcons + " fab fa-facebook"} />
              </a>
              <br/>
            Suivez-nous sur Instagram <a
                href="https://www.instagram.com/painkillar_therapy/"
                className={classes.block}
                target="_blank"
              >
              <i className={classes.socialIcons + " fab fa-instagram"} />
              </a>
              <br/>
            Regardez-nous sur YouTube <a
                href="https://www.youtube.com/channel/UCwzpbSypWtAzxd-FH37GNWg"
                className={classes.block}
                target="_blank"
              >
              <i className={classes.socialIcons + " fab fa-youtube"} />
              </a>
          </h4>
   
        </GridItem>
      </GridContainer>
    </div>
  );
}
