import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// nodejs library that concatenates classes
import classNames from "classnames";

// @material-ui/icons
import Chat from "@material-ui/icons/Chat";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import Fingerprint from "@material-ui/icons/Fingerprint";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/useStyle.js";
import { Box } from "@material-ui/core";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardContent from '@material-ui/core/CardContent';

import CardMedia from '@material-ui/core/CardMedia';

import CardFooter from "components/Card/CardFooter.js";
import Button from "components/CustomButtons/Button.js";
import SubIcon from '@material-ui/icons/Subscriptions';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import LinkIcon from '@material-ui/icons/Link';
import Hidden from '@material-ui/core/Hidden';

import step1 from "assets/img/Card1.png";
import step2 from "assets/img/Card2.png";
import step3 from "assets/img/Card3.png";


import needleGIF from "assets/img/NeedleVRGIF.gif";
import lindraGIF from "assets/img/FastLindraGIF.gif";
import breathGIF from "assets/img/breathARGIF.gif";

const useStyles = makeStyles(styles);

export default function OurProductsSection() {
  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  return (
    <div id="Products" className={classes.section}>
      <div>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={12}>
          <h2 className={classes.title}>Nos Produits</h2>

        </GridItem>
      </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={8}>
            <Card>
                <CardBody>
                    <GridContainer>
                    <GridItem xs={12} sm={12} md={6} spacing={1}>
                    <h3 className={classes.cardTitle}>Soins Douloureux</h3>

                        <CardMedia
                            component="img"
                            className={classes.cardImage} 
                            image={needleGIF}
                            title="NeedleVR"
                        />       
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6} spacing={1}>
                        <CardBody>
                        <p className={classes.description}>
                            <b>Pansements, sutures, prises de sang, soins de brûlures...</b> Autant de gestes
                            médicaux désagréables ou douloureux. Fort de notre expérience auprès des <b>professionnels de santé</b>,
                            en <b>rhumatologie</b>, en <b>pédiatrie</b> et en <b>biologie médicale</b>, nous avons développé <b>NeedleVR</b>, solution alliant objets connectés et réalité virtuelle,
                            pour rendre la vie des patients plus facile et faire en sorte que ces soins tant redoutés passent comme une lettre à la Poste.  
                            </p>
                        </CardBody>
                        <Button className={classes.alignItemsAndJustifyContent}
                              color="primary"
                              size="lg"
                              href="https://youtu.be/5lwBrOLFs3c"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <PlayCircleFilledIcon/>
                              Je regarde une démo
                            </Button>
                    </GridItem>
                    </GridContainer>
                </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

        <GridContainer>
            <GridItem xs={12} sm={12} md={4} />
            <GridItem xs={12} sm={12} md={8} >
                <Card>
                    <CardBody>
                        <GridContainer>
                        <GridItem xs={12} sm={12} md={6} spacing={1}>
                        <h3 className={classes.cardTitle}>Douleur Chronique</h3> 
                        <CardMedia
                            component="img"
                            className={classes.cardImage} 
                            image={lindraGIF}
                            title="lindra"
                        />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6} spacing={1}>
                            <CardBody>
                                <p className={classes.description}>
                                Pour celles et ceux qui souffrent de <b>douleur chronique</b> et qui cherchent une solution de gestion de la douleur à faire chez soi,
                                nous avons développé l'application <b>Lindra</b>. Lindra est une <b>application mobile en réalité virtuelle</b> compatible avec les smartphones <b>Android</b> (iPhone en beta) 
                                et les casques <b>Google Cardboard</b>. Lindra est une solution non-médicamenteuse <b>simple</b>, <b>efficace</b> et <b>économique</b>.
                                </p>
                            </CardBody>
                            <Button className={classes.alignItemsAndJustifyContent}
                              color="rose"
                              size="lg"
                              href="https://www.lindra.co"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <LinkIcon/>
                              Je visite lindra.co
                            </Button>
                        </GridItem>
                      </GridContainer>  
                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>

        <GridContainer direction="row" justify="space-evenly" alignItems="center">
            <GridItem xs={12} sm={12} md={8} >
                <Card>
                    <CardBody>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={6} spacing={1}>
                            <h3 className={classes.cardTitle}>Stress et Anxiété</h3>
                            <CardMedia
                                component="img"
                                className={classes.cardImage} 
                                image={breathGIF}
                                title="breath"
                            />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6} spacing={1}>
                                <CardBody>
                                    <p className={classes.description}>
                                    Née d'une <b>demande de nos utilisateurs</b> qui souhaitaient une <b>
                                      solution simple et ludique pour gérer
                                      le stress
                                    </b> dû au confinement et à l'épidémie de Covid-19, <b>BreathAR</b> est une application de cohérence cardiaque
                                    en <b>réalité augmentée</b>. Idéal pour apprendre à respirer et se recentrer sur son souffle. Pas de casque de réalité virtuelle
                                    nécessaire. Disponible sur smartphone <b>Android</b>.
                                    </p>
                                </CardBody>
                                <Button className={classes.alignItemsAndJustifyContent}
                              color="primary"
                              size="lg"
                              href="https://app.lindra.co/register"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <SubIcon/>
                              Je m'inscris
                            </Button>
                            </GridItem>
                        </GridContainer>
                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}