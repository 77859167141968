import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Camera from "@material-ui/icons/Camera";
import Palette from "@material-ui/icons/Palette";
import Favorite from "@material-ui/icons/Favorite";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import NavPills from "components/NavPills/NavPills.js";
import Parallax from "components/Parallax/Parallax.js";
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import SubIcon from '@material-ui/icons/Subscriptions';

import profile from "assets/img/team/Guillaume-Palacios-200x200.jpg";


import neuroPain from "assets/img/illustrationNeuropathicPain.png";
import piano from "assets/img/piano.jpg";


import styles from "assets/jss/material-kit-react/views/blogPost.js";

const useStyles = makeStyles(styles);

export default function TalkToDoctor(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);
  return (
    <div>
      <Header
        color="transparent"
        brand=" BLOG"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "darkPK"
        }}
        {...rest}
      />
      <Parallax small filter image={require("assets/img/Doctor-patient.jpeg")} />
      <div className={classNames(classes.main)}>
        <div>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={6}>
                <div className={classes.profile}>
                  <div>
                    <img src={profile} alt="..." className={imageClasses} />
                  </div>
                  <div className={classes.name}>
                    <h3 className={classes.title}>COMMENT PARLER DE DOULEUR AVEC SON DOCTEUR ?</h3>
                    <h6>Guillaume Palacios, PhD</h6>
                    <h6>co-fondateur de PainkillAR</h6>
                    {/* <Button justIcon link className={classes.margin5}>
                      <i className={"fab fa-twitter"} />
                    </Button>
                    <Button justIcon link className={classes.margin5}>
                      <i className={"fab fa-instagram"} />
                    </Button>
                    <Button justIcon link className={classes.margin5}>
                      <i className={"fab fa-facebook"} />
                    </Button> */}
                  </div>
                </div>
              </GridItem>
            </GridContainer>
            <div className={classes.abstract}>
            Quelques conseils pour tirer le plus de bénéfices d’une consultation avec votre spécialiste de la douleur.
            </div>


            <div className={classes.text}>    
            Quand on a mal, les journées peuvent être longues et difficiles. Long et difficile est aussi le chemin pour trouver des solutions adaptées à soi et à sa douleur.
            </div>

<div className={classes.text}>
Une douleur peut avoir de nombreuses causes sous-jacentes. De même, il existe de nombreux types de douleurs et chacun vit la douleur différemment. 
Beaucoup d’entre nous sommes suivis dans un Centre d’Évaluation ou de Traitement de la Douleur (CETD) ou par un docteur référent douleur (que ce soit un ou une algologue, rhumatologue, neurologue etc.). 
Afin que ce ou cette spécialiste de la douleur puisse nous aider à développer une stratégie thérapeutique sur mesure, il est essentiel qu’il ou elle ait les meilleures informations. 
<b> Car un bon traitement commence par une bonne communication avec son spécialiste.</b>
</div>


            <h3 className={classes.sectionHeader}>Pour une communication efficace</h3>
<div className={classes.text}>
Les informations que nous, patients, donnons à notre médecin et la façon dont nous les présentons, sont un élément central d’une stratégie de traitement de la douleur efficace.
Il faut présenter les informations relatives à notre santé de façon concise, synthétique et cohérente et trouver le bon équilibre entre pas assez d’information et trop d’informations.
</div>

            <h3 className={classes.sectionHeader}>Pas assez d’information …</h3>
<div className={classes.text}>
Par exemple, il n’est pas suffisant de dire à son docteur : “Docteur, j’ai mal tout le temps !” 😅
</div>

<div className={classes.text}>
En effet, le praticien devra connaître <b>la nature et le type de douleur</b> que vous ressentez. La plupart des gens pensent que la douleur est toujours la même, mais il existe en fait plusieurs types de douleur. Et là encore, plus vous permettrez aux médecins de comprendre votre situation et vos défis, meilleure sera leur chance de trouver des traitements qui conviennent à votre type spécifique de douleur.
</div>

            <h3 className={classes.sectionHeader}>Trop d’informations …</h3>
<div className={classes.text}>
D’un autre côté, en tant que patient, on ne peut pas arriver devant son médecin avec un discours décousu et raconter l’intégralité de son historique médical, professionnel et émotionnel pendant une consultation de quelques dizaines de minutes. Il convient de se mettre à la place du médecin. Elle a beaucoup de patients à voir pendant la journée, tous avec des pathologies et des histoires différentes. Cela arrivera peut-être un jour, mais pour l’instant, nous ne sommes pas traités par des robots mais par des hommes et des femmes avec leurs limites. Ils ne peuvent pas retenir tous les détails d’une consultation et inférer tous les tenants et aboutissants. Pour améliorer la communication entre soignant et soigné, il tient à ce dernier de présenter son problème de façon claire. C’est quelque chose qui se travaille et nous allons vous donner des conseils dans ce qui suit.
</div>

            <h3 className={classes.sectionHeader}>Les points à savoir pour avoir une conversation productive avec votre médecin de la douleur</h3>
            <h3 className={classes.sectionHeader}>La douleur chronique est difficile à diagnostiquer</h3>

<div className={classes.text}>
On a aujourd’hui une théorie solide de la douleur aiguë. Dans le sens le plus élémentaire, c’est un processus biologique qui nous protège. Lorsque nous nous blessons, notre corps produit des molécules qui envoient des messages de douleur à notre moelle épinière. La moelle épinière transmet ces messages à notre cerveau, qui les traitent et produisent une sensation de douleur. En général, traiter la blessure ou localiser la source de l’inflammation suffit à apaiser la douleur aigüe. Les antalgiques sont aussi souvent efficaces contre la douleur aigüe.
</div>

<div className={classes.text}>
Par contre, pour ce qui est de la douleur chronique — qui dure des semaines, des mois ou des années — c’est un peu plus compliqué. Souvent, c’est une douleur sans but apparent. Elle peut traîner après une <b>blessure</b> ou une <b>intervention chirurgicale</b>, ou résulter d’une condition médicale comme l’<b>arthrite</b> ou la <b>fibromyalgie</b>. Au fil du temps, la douleur chronique peut également entraîner des changements dans votre système nerveux qui affectent la façon dont vous percevez la douleur.
</div>

<div className={classes.text}>
Malheureusement, la douleur chronique n’est généralement pas causée par un seul problème — elle est rarement aussi simple. Au contraire, la douleur chronique est souvent associée à un certain nombre d’interactions complexes qui jouent des rôles différents dans la création de signaux de douleur à partir d’un site de lésion cérébrale.
</div>

<h3 className={classes.sectionHeader}>Aidez votre docteur à vous aider</h3>

<div className={classes.text}>
Si vous voyez un spécialiste de la douleur, surtout si c’est pour la première fois, voici ce que vous devez apporter à votre rendez-vous :
<ul>
  <li>
    Une liste de diagnostics médicaux, de chirurgies ou de procédures récentes.
  </li>
  <li>
    Une liste à jour des médicaments que vous prenez. Votre médecin voudra savoir spécifiquement comment vous prenez réellement vos médicaments, même si cela est légèrement différent de ce qui est écrit sur la boîte. Par exemple, si vos comprimés sont censés être pris trois fois par jour et que vous ne les prenez qu’une seule fois, il s’agit d’un détail important qui contribuera à améliorer la façon dont votre médecin élaborera votre plan de traitement.
  </li>
  <li>
    Une liste des traitements que vous avez essayés et pourquoi vous les avez arrêtés. Il y a peut-être à cause d’un effet secondaire, ou tout simplement parce qu’ils n’ont pas fonctionné.
  </li>
</ul>

</div>

<h3 className={classes.sectionHeader}>Comment bien décrire votre douleur</h3>
<div className={classes.text}>
Même s’il y a des recherches sur le sujet, il n’y a malheureusement pas de mesure 100% fiable et objective de la douleur. Il peut être difficile pour les médecins d’évaluer le niveau de douleur ressentie par leurs patients et l’efficacité des traitements.
</div>

<div className={classes.text}>
Pour évaluer votre douleur, votre médecin peut vous demander d’évaluer votre douleur sur une échelle de 1 à 10, ou demander des études d’imagerie telles qu’une IRM ou une tomodensitométrie. Bien que ces outils puissent être utiles, ils ne racontent souvent pas toute l’histoire. Votre médecin s’appuiera donc également beaucoup sur vos réponses aux questions sur votre douleur. Il vous posera notamment les questions suivantes :
<ul>
  <li>
    Où est-ce que ça fait mal ?
  </li>
  <li>
    Quand avez-vous mal ?
  </li>
  <li>
    À quelle fréquence ?
  </li>
  <li>
    Qu’est-ce qui accentue ou atténue la douleur ? Un mouvement ? Une situation ? Un stress émotionnel ?
  </li>
  <li>
    Comment votre douleur affecte-t-elle votre vie au quotidien ?
  </li>
  <li>
    Comment décririez-vous votre douleur ?
  </li>
</ul>
Cette dernière question — décrire à quoi ressemble votre douleur — ne vient pas naturellement à la plupart des gens. Mais être capable de communiquer efficacement ce que vous ressentez peut aider votre médecin à mieux adapter les traitements.
</div>
<GridContainer justify="center">
              <GridItem xs={12} sm={12} md={8} className={classes.navWrapper}>
                        <GridContainer justify="center">
                          <GridItem xs={12} sm={12} md={8}>
                            <img
                              alt="..."
                              src={neuroPain}
                              className={navImageClasses}
                            />
                            Les quatre caractéristiques les plus fréquentes des douleurs neuropathiques : les sensations de brûlure, de décharges électriques, de coups de couteau ou de fourmillements.
                          </GridItem>
                        </GridContainer>
              </GridItem>
            </GridContainer>
<div className={classes.text}>
Voici quelques mots qui pourraient vous aider pour décrire précisément votre douleur :
<ul>
  <li>
    Sensation de tremblement, de pression, de piqûre.
  </li>
  <li>
    Douleur pulsatile, pénétrante, dévorante, ou lancinante.
  </li>
  <li>
    Sensation d’avoir les membres engourdis ou endoloris, d’avoir des crampes.
  </li>
  <li>
    Sensation de décharges électriques, de coups de poignard, fourmillements.
  </li>
  <li>
    Douleur écrasante, qui tire, qui perce et arrache.
  </li>
  <li>
    Sensation de brûlure, de tranchant, de démangeaisons
  </li>
  <li>
    Douleur rayonnante, irradiante.
  </li>
  <li>
    Sensation d’un objet coupant, picotements, piercing.
  </li>
  <li>
    Pincements, picotements, serrements.
  </li>
</ul>
</div>

<h3 className={classes.sectionHeader}>Plus de conseils pour un rendez-vous productif avec votre médecin de la douleur</h3>
<div className={classes.text}>
  <ul>
  <li>
    Venez préparé.e et avec des questions.
  </li>
<li>
  Avant votre rendez-vous, notez quelques points importants, questions ou préoccupations que vous souhaitez aborder avec votre médecin. Vous et votre médecin aurez beaucoup à couvrir, donc cette étape supplémentaire peut vous aider à ne rien oublier.
</li>
<li>
  Apportez une deuxième paire d’oreilles. Certaines personnes trouvent utile d’amener un ami ou un membre de la famille. Si vous voyez virtuellement un spécialiste de la douleur, demandez si la plateforme de télé-consultation que vous utilisez permet aux membres de la famille de participer à votre rendez-vous.
</li>
<li>
  Tenez un journal de la douleur. Votre médecin voudra savoir dans quelle mesure votre douleur limite votre vie. Avant votre rendez-vous, notez comment votre douleur affecte votre humeur et votre capacité à marcher, à dormir et à profiter des activités.
</li>
  </ul>
  Le succès d’un traitement anti-douleur se mesure au nombre de choses que les patients peuvent faire grâce à la thérapie et de leurs progrès : diminution de la douleur bien sûr, mais aussi le fait de recouvrir sa mobilité et de gagner en autonomie.
</div>

<div className={classes.text}>
Soyez ouvert aux solutions. Il pourrait y avoir des facteurs contribuant à votre douleur auxquels vous pourriez ne pas penser habituellement. 
Par exemple, certains aspects de la santé psychique tels que la gestion de l’<b>humeur</b> ou du <b>stress</b> sont connus pour diminuer l’intensité de la douleur. 
Une psychologue spécialisée dans la gestion de la douleur peut être recommandée à certains patients. 
De même, de nombreuses <b>thérapies non-médicamenteuses</b> sont de plus en plus plébiscitées dans les centres anti-douleurs telles que la <b>méditation pleine conscience</b>, l’<b>hypnose</b> ou encore les nouvelles technologies comme la <a href="https://lindra.co"><b>réalité virtuelle</b></a>. Ces techniques complémentaires aux médicaments et alternatives offrent de très bon résultats. Donc parlez-en à votre médecin.
</div>
<GridContainer justify="center">
              <GridItem xs={12} sm={12} md={8} className={classes.navWrapper}>
                        <GridContainer justify="center">
                          <GridItem xs={12} sm={12} md={8}>
                          <Button
          href="https://www.lindra.co"
          color="rose"
          target="_blank"
        >
          <SubIcon/>testez la réalité virtuelle anti-douleur
        </Button>
                          </GridItem>
                        </GridContainer>
              </GridItem>
            </GridContainer>

            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={8} className={classes.navWrapper}>
                        <GridContainer justify="center">
                          <GridItem xs={12} sm={12} md={8}>
                            <img
                              alt="..."
                              src={piano}
                              className={navImageClasses}
                            />
                            Le soin de la douleur est une pièce écrite pour quatre mains : le patient et son docteur ont tous les deux une part importante à jouer.
                          </GridItem>
                        </GridContainer>
              </GridItem>
            </GridContainer>
<h3 className={classes.sectionHeader}>Conclusion</h3>
<div className={classes.text}>
Le soin de la douleur est une pièce écrite pour quatre mains : le patient et son docteur ont tous les deux une part importante à jouer. Pour une prise en charge efficace de votre douleur chronique, mettez toutes les chances de votre côté et préparez consciencieusement vos consultations avec votre spécialiste en lui donnant le bon niveau d’information. Ni trop, ni pas assez. <b>Soyez concis, précis et surtout descriptif.</b> Car mieux votre médecin vous comprendra, mieux il vous soignera.
</div>


<h3 className={classes.sectionHeader}>Référence</h3>
<div className={classes.text}>
Cet article est librement inspiré, annoté et traduit de <a href="https://health.clevelandclinic.org/how-to-talk-to-your-doctor-about-pain/">https://health.clevelandclinic.org/how-to-talk-to-your-doctor-about-pain/</a>
</div>



            
            
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={8} className={classes.navWrapper}>
                        <GridContainer justify="center">
                          <GridItem xs={12} sm={12} md={6}>
                          <Button
                            target="_blank" 
                            href="https://twitter.com/intent/tweet?text=Histoire%20de%20PainkillAR%20Réalité%20Virtuelle%20Anti-Douleur&via=Painkill_AR&url=https%3A%2F%2Fpainkillar.com%2Fblog%2Fhowto-talk-to-doctor"
                            color="twitter">
        <i
          className={"fab fa-twitter"}
        />{" "}
        Partager sur Twitter
      </Button>
      </GridItem>
                          <GridItem xs={12} sm={12} md={6}>
                          <Button 
                          color="facebook"
                          target="_blank" 
                          href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fpainkillar.com%2Fblog%2Fhowto-talk-to-doctor&amp;src=sdkpreparse"
                          >
        <i
          className={" fab fa-facebook-square"}
        />{" "}
        Partager sur Facebook
      </Button>
                          </GridItem>
                        </GridContainer>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
