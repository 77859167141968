import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";

import styles from "assets/jss/material-kit-react/views/landingPage.js";
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

// Sections for this page
import ProductSection from "./Sections/ProductSection.js";
import UseSection from "./Sections/UseSection.js";
import TestimonialsSection from "./Sections/TestimonialsSection.js";
import Contact from "./Sections/Contact.js";
import TeamSection from "./Sections/TeamSection.js";
import MedSection from "./Sections/MedSection.js";
import SocialButtons from "./Sections/SocialButtons.js";
import CardExpertiseSection from "./Sections/OurProductsSection.js";
import WorkSection from "./Sections/WorkSection.js";
import DNASection from "./Sections/DNASection.js";
import OurProductsSection from "./Sections/OurProductsSection.js";
import PartnersSection from "./Sections/PartnersSection.js";
import SectionCarousel from "views/Components/Sections/SectionCarousel.js";
import PressSection from "./Sections/PressSection.js";
import MailChimpForm from "./MailChimpForm.js";


const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function LandingPagePainkillAR(props) {
  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div id= "Home">
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand=""
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "darkPK"
        }}
        {...rest}
      />
      <Parallax  image={require("assets/img/LindraBGSmall3.png")}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <h1 className={classes.title}>Nous développons les anti-douleurs de demain.</h1>
              <br />
              
            </GridItem>
          </GridContainer>

      <SocialButtons />
      <MailChimpForm />


        </div>
      </Parallax>
      <div className={classNames(classes.main)}>
        <div className={classes.container}>
          {/* <ProductSection /> */}
          <DNASection />
          <OurProductsSection />
          <TeamSection />
          <PartnersSection />
          <PressSection />
          {/* <TestimonialsSection /> */}
          <Contact />
        </div>
      </div>
      <Footer />
    </div>
  );
}
