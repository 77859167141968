import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Camera from "@material-ui/icons/Camera";
import Palette from "@material-ui/icons/Palette";
import Favorite from "@material-ui/icons/Favorite";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import NavPills from "components/NavPills/NavPills.js";
import Parallax from "components/Parallax/Parallax.js";
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import SubIcon from '@material-ui/icons/Subscriptions';

import profile from "assets/img/team/Guillaume-Palacios-200x200.jpg";


import bonica from "assets/img/bonica-portrait.jpg";


import styles from "assets/jss/material-kit-react/views/blogPost.js";

const useStyles = makeStyles(styles);

export default function TalkToDoctor(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);
  return (
    <div>
      <Header
        color="transparent"
        brand=" BLOG"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "darkPK"
        }}
        {...rest}
      />
      <Parallax small filter image={require("assets/img/bonica-wrestler.jpeg")} />
      <div className={classNames(classes.main)}>
        <div>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={6}>
                <div className={classes.profile}>
                  <div>
                    <img src={profile} alt="..." className={imageClasses} />
                  </div>
                  <div className={classes.name}>
                    <h3 className={classes.title}>HOMMAGE AU DR. JOHN BONICA, PIONNIER DE LA MÉDECINE DE LA DOULEUR</h3>
                    <h6>Guillaume Palacios, PhD</h6>
                    <h6>co-fondateur de PainkillAR</h6>
                    {/* <Button justIcon link className={classes.margin5}>
                      <i className={"fab fa-twitter"} />
                    </Button>
                    <Button justIcon link className={classes.margin5}>
                      <i className={"fab fa-instagram"} />
                    </Button>
                    <Button justIcon link className={classes.margin5}>
                      <i className={"fab fa-facebook"} />
                    </Button> */}
                  </div>
                </div>
              </GridItem>
            </GridContainer>
            <div className={classes.abstract}>
            Ce 15 août, on fête l’anniversaire de la mort de John Bonica, médecin américain d’origine italienne. Ce nom n’évoque peut-être rien pour beaucoup d’entre nous mais c’est un grand Monsieur de la médecine.
            </div>

            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={8} className={classes.navWrapper}>
                        <GridContainer justify="center">
                          <GridItem xs={12} sm={12} md={8}>
                            <img
                              alt="..."
                              src={bonica}
                              className={navImageClasses}
                            />
Photo Dr. John J. Bonica. American Society of Anesthesiologists (ASA) Presidents
                          </GridItem>
                        </GridContainer>
              </GridItem>
            </GridContainer>
            <div className={classes.text}>    
            Pionnier de la médecine de la douleur, John Bonica fut médecin anesthésiste, algologue et lutteur. Il a été champion du monde poids lourd-léger de lutte tout en poursuivant de brillantes études médicales.            </div>

<div className={classes.text}>
Dans sa vie, il a été souvent confronté à la douleur. En tant que médecin, auprès des soldats revenant de la guerre du Pacifique. En tant que mari, témoin de l’accouchement difficile de sa femme lors de la naissance de leur première fille. Enfin, en tant que patient, les conséquences de son passé de lutteur ont fait de lui un sujet à la douleur chronique : opéré 18 fois, il sait de quoi il parle …
</div>


<div className={classes.text}>
Il deviendra l’un des plus importants promoteurs de l’étude sur le traitement de la douleur. Fondateur de la WSSA (Washington State Society of Anesthesiologists) et de l’ASA (American Society of Anesthesiologists), John Bonica a écrit et coopéré à la préparation de plus de 60 livres et plus de 270 articles scientifiques dont <b>The Management of Pain</b> (La Thérapie de la Douleur), encyclopédie en trois volumes et <b>Principles and Practice of Obstetric and Analgesic Anesthesia</b> (Analgésie, anesthésie obstétricale) recueille toutes les connaissances de l’époque sur ce sujet. Il a été honoré par les plus grandes universités mondiales.
</div>

<div className={classes.text}>
En 1973, il réunira dans l’état de Washington 350 médecins de toutes spécialités, pour un colloque exclusivement dédié au thème de la douleur. Pour la première fois de l’histoire de la médecine, émerge l’idée simple de réunir des spécialistes d’origines différentes en une équipe dite pluridisciplinaire afin de tenter de résoudre des problèmes de douleur complexe pour lesquelles les options thérapeutiques ordinaires n’avaient pas de solutions. Ce colloque inaugural servira de fondement à la création de l’IASP (International Association for the Study of Pain — Association Internationale pour l’Étude de la Douleur).</div>

<div className={classes.text}>
John Bonica aura également permis de nombreux progrès en obstétrique. Les femmes lui doivent des avancées importantes dans le domaine de l’anesthésie dont la péridurale et l’anesthésie locale.
</div>

<div className={classes.text}>
Globetrotter, John Bonica a été aussi un formateur et un enseignant. Il a formé nombre de médecins et d’anesthésistes. Il a aidé à la création des centres de formation Anestesiologa Latino au Venezuela et à Manille. Et il fut le conseiller médical officiel des gouvernements argentins, brésiliens, italiens, suédois et japonais.
</div>

<div className={classes.text}>
Jusqu’à sa mort survenue le 15 août 1994, la douleur aura été sa compagne de route. Il aura permis de faire reconnaître la douleur chronique comme une véritable maladie et non plus un simple symptôme. Toute sa vie il aura été en empathie avec ses patients.
</div>

<div className={classes.text}>
Avec ce grand médecin, la douleur est enfin considérée comme une discipline médicale à part entière. C’est pourquoi, chez PainkillAR, nous souhaitions lui rendre hommage aujourd’hui.
</div>

<div className={classes.text}>
Pour en savoir plus sur John Bonica, je vous invite à regarder la vidéo de TED listée dans les Références ci-dessous. Et pour en savoir plus sur PainkillAR et notre travail dans le domaine de la réalité virtuelle et de la douleur chronique rendez-vous sur <a href="https://www.painkillar.com"><b>https://www.painkillar.com</b></a> et <a href="https://www.lindra.co"><b>https://www.lindra.co</b></a>
</div>

<div className={classes.text}>
À bientôt.

</div>




<h3 className={classes.sectionHeader}>Références</h3>
<div className={classes.text}>
  <ul>
    <li><a href="https://en.wikipedia.org/wiki/John_Bonica">https://en.wikipedia.org/wiki/John_Bonica</a></li>
    <li>Site de l’ISAP <a href="https://www.iasp-pain.org/">https://www.iasp-pain.org/</a></li>
    <li><a href="https://www.ted.com/talks/latif_nasser_the_amazing_story_of_the_man_who_gave_us_modern_pain_relief">https://www.ted.com/talks/latif_nasser_the_amazing_story_of_the_man_who_gave_us_modern_pain_relief</a></li>
  </ul>
</div>



            
            
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={8} className={classes.navWrapper}>
                        <GridContainer justify="center">
                          <GridItem xs={12} sm={12} md={6}>
                          <Button
                            target="_blank" 
                            href="https://twitter.com/intent/tweet?text=Histoire%20de%20PainkillAR%20Réalité%20Virtuelle%20Anti-Douleur&via=Painkill_AR&url=https%3A%2F%2Flindra.co%2Flindra-blog%2Fwhy-painkillar"
                            color="twitter">
        <i
          className={"fab fa-twitter"}
        />{" "}
        Partager sur Twitter
      </Button>
      </GridItem>
                          <GridItem xs={12} sm={12} md={6}>
                          <Button 
                          color="facebook"
                          target="_blank" 
                          href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Flindra.co%2Flindra-blog%2Fwhy-painkillar&amp;src=sdkpreparse"
                          >
        <i
          className={" fab fa-facebook-square"}
        />{" "}
        Partager sur Facebook
      </Button>
                          </GridItem>
                        </GridContainer>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
