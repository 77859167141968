import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/teamStyle.js";


import guillaume from "assets/img/team/Guillaume-Palacios-200x200.jpg";
import irshad from "assets/img/team/Irshad-Abibouraguimane-200x200.jpg";
import richard from "assets/img/team/richard-200x200.png";

import damien from "assets/img/team/Damien-du-Perron-200x200.jpeg";
import florian from "assets/img/team/Florian-Bailly-200x200.jpg";
import eric from "assets/img/team/Eric-Serra-200x200.jpg";

const useStyles = makeStyles(styles);

export default function TeamSection() {
  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  return (
    <div id="Team" className={classes.section}>
      <h2 className={classes.title}>L'Équipe PainkillAR</h2>
      <h3 className={classes.title}>Équipe Exécutive</h3>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={guillaume} alt="..." className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
                Guillaume Palacios
                <br />
                <small className={classes.smallTitle}>CEO</small>
              </h4>
              <CardBody>
                <p className={classes.description}>
                  Ancien chercheur en physique théorique, et ancien ingénieur R&D chez Criteo, Guillaume est un esprit scientifique passionné 
                  par les applications de la technologie dans le champ de la médecine. Lui même patient douloureux chronique et frustré par le 
                  peu d'avancée sur le sujet de la douleur, il crée PainkillAR fin 2018. Il est en charge de la vision, de la commercialisation et des partenariats scientifiques.
                </p>
              </CardBody>
              <CardFooter className={classes.justifyCenter}>
                <Button
                  justIcon
                  color="transparent"
                  className={classes.margin5}
                  href="https://www.linkedin.com/in/guillaume-palacios-02451521/"
                  target="_blank"
                >
                  <i className={classes.socials + " fab fa-linkedin"} />
                </Button>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={irshad} alt="..." className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
                Irshad Abibouraguimane
                <br />
                <small className={classes.smallTitle}>CTO</small>
              </h4>
              <CardBody>
                <p className={classes.description}>
                  Après des études d'ingénieur à Centrale Nantes, Irshad part pour le Japon où il travaille à l'université de Keio
                  sur des algorithmes de classification d'images pour la chirurgie. À son retour en France début 2019, il rencontre
                  Guillaume et partage avec lui la vision que la réalité virtuelle va révolutionner la médecine. Virtuose des algorithmes
                  et des simulations 3D, Irshad est en charge de la recherche et du développement des produits PainkillAR.
                </p>
              </CardBody>
              <CardFooter className={classes.justifyCenter}>
                <Button
                  justIcon
                  color="transparent"
                  className={classes.margin5}
                  href="https://www.linkedin.com/in/irshadabib/"
                  target="_blank"
                >
                  <i className={classes.socials + " fab fa-linkedin"} />
                </Button>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={richard} alt="..." className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
                Richard Arnaud
                <br />
                <small className={classes.smallTitle}>Développeur VR</small>
              </h4>
              <CardBody>
                <p className={classes.description}>
                  Codeur passionné de réalité virtuelle et d'interfaces homme-machine, Richard est étudiant à l'École Centrale de Nantes. Il rejoint PainkillAR en plein confinement.
                  Il travaille donc en télétravail depuis Nantes. Il est en charge du développement des nouveaux <i>features</i> de Lindra, l'application phare de PainkillAR pour la
                  gestion de la douleur chronique. Il est également à l'initiative du projet BreathAR de cohérence cardiaque en réalité augmentée.
                </p>
              </CardBody>
              <CardFooter className={classes.justifyCenter}>
              <Button
                  justIcon
                  color="transparent"
                  className={classes.margin5}
                  href="https://www.linkedin.com/in/richard-arnaud-119858199/"
                  target="_blank"
                >
                  <i className={classes.socials + " fab fa-linkedin"} />
                </Button>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
      <h3 className={classes.title}>Conseil Scientifique</h3>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={florian} alt="..." className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
                Dr. Florian Bailly
                <br />
                <small className={classes.smallTitle}>Rhumatologue et Médecin de la Douleur<br/>Hôpital Pitié Salpêtrière - APHP - Paris</small>
              </h4>
              <CardBody>
                <p className={classes.description}>
                  Florian est un jeune rhumatologue et spécialiste de la douleur exerçant au CETD (Centre d'Évaluation et
                  de Traitement de la Douleur) de l'Hôpital de la Pitié-Salpêtrière à Paris. Membre de l'Association Française
                  de Rhumatologie, Florian incarne cette nouvelle génération de médecins geek ultra-pointus cherchant sans cesse à innover
                  et à explorer de nouvelles techniques thérapeutiques.
                </p>
              </CardBody>
              <CardFooter className={classes.justifyCenter}>
                <Button
                  justIcon
                  color="transparent"
                  className={classes.margin5}
                  href="https://twitter.com/baillyflo"
                  target="_blank"
                >
                  <i className={classes.socials + " fab fa-twitter"} />
                </Button>
                <Button
                  justIcon
                  color="transparent"
                  className={classes.margin5}
                  href="https://www.linkedin.com/in/florian-bailly-82389997/"
                  target="_blank"
                >
                  <i className={classes.socials + " fab fa-linkedin"} />
                </Button>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={damien} alt="..." className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
                Dr. Damien du Perron
                <br />
                <small className={classes.smallTitle}>Hypnothérapeute et Gériatre<br/>Hôpital Pitié Salpêtrière - APHP - Paris</small>
              </h4>
              <CardBody>
                <p className={classes.description}>
                  Gériatre de formation, Damien est connu à Paris pour être l'un des référents du domaine de l'hypno-analgésie, la pratique
                  de l'hypnose pour la gestion de la douleur. Il anime notamment les sessions d'hypnose au CETD de la Pitié-Salpêtrière à Paris.
                  Il a contribué à intégrer de nombreux concepts d'hypno-analgésie dans les applications de réalité virtuelle de PainkillAR.
                </p>
              </CardBody>
              <CardFooter className={classes.justifyCenter}>
                <Button
                  justIcon
                  color="transparent"
                  className={classes.margin5}
                  href="https://www.linkedin.com/in/damien-du-perron-741a377b/"
                  target="_blank"
                >
                  <i className={classes.socials + " fab fa-linkedin"} />
                </Button>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={eric} alt="..." className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
                Pr. Éric Serra
                <br />
                <small className={classes.smallTitle}>Professeur Associé Médecine de la Douleur<br/>Université Picardie Jules Verne - Amiens</small>
              </h4>
              <CardBody>
                <p className={classes.description}>
                  Psychiatre de formation, Éric est chef de service Douleur du Centre Hospitalo-Universitaire d'Amiens. Il est également Professeur Associé 
                  en Médecine de la Douleur à l'Université de Picardie. Éric est aussi président de la Société Française des Thérapies Non Médicamenteuses et 
                  est impliqué dans plusieurs projets de MedTech sur le sujet de la douleur.
                </p>
              </CardBody>
              <CardFooter className={classes.justifyCenter}>
                <Button
                  justIcon
                  color="transparent"
                  className={classes.margin5}
                  href="https://twitter.com/serraeric6"
                  target="_blank"
                >
                  <i className={classes.socials + " fab fa-twitter"} />
                </Button>
                <Button
                  justIcon
                  color="transparent"
                  className={classes.margin5}
                  href="https://www.linkedin.com/in/eric-serra-01009444/"
                  target="_blank"
                >
                  <i className={classes.socials + " fab fa-linkedin"} />
                </Button>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
