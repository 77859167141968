import React, {Component} from "react";
import Email from "@material-ui/icons/Email";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import InputAdornment from "@material-ui/core/InputAdornment";

import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import MailchimpSubscribe from "react-mailchimp-subscribe"
import Input from '@material-ui/core/Input';

const url = "https://painkillar.us17.list-manage.com/subscribe/post?u=6b0c22566fb441808566b7d9c&amp;id=19147e362d";


// a basic form
const CustomForm = ({ status, message, onValidated }) => {
    let email;
    const submit = () =>
      email &&
      email.value.indexOf("@") > -1 &&
      onValidated({
        EMAIL: email.value,
      });
  
    return (
      <div
        // style={{
        //   background: "#efefef",
        //   borderRadius: 2,
        //   padding: 10,
        //   display: "inline-block"
        // }}
      >
        {status === "sending" && <div style={{ color: "blue" }}>sending...</div>}
        {status === "error" && (
          <div
            style={{ color: "red" }}
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}
        {status === "success" && (
          <div
            style={{ color: "green" }}
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}
        <h4>Je m'inscris à la newsletter pour recevoir les dernières infos de PainkillAR</h4>
        <input
          style={{ fontSize: "2em", padding: 5 }}
          ref={node => (email = node)}
          type="email"
          placeholder="Mon email"
        />
        <br />
        {/* <button style={{ fontSize: "2em", padding: 5 }} onClick={submit}>
          Submit
        </button> */}
               <Button
                 color="rose"
                 size="lg"
                 rel="noopener noreferrer"
                 onClick={submit}
               >
                 Je m'inscris
               </Button>
      </div>
    );
  };

// use the render prop and your custom form
const MailChimpForm = () => (
    <MailchimpSubscribe
      url={url}
      render={({ subscribe, status, message }) => (
        <div>
          <CustomForm status={status}
              message={message}
              onValidated={formData => subscribe(formData)} />
          {/* {status === "sending" && <div style={{ color: "blue" }}>sending...</div>}
          {status === "error" && <div style={{ color: "red" }} dangerouslySetInnerHTML={{__html: message}}/>}
          {status === "success" && <div style={{ color: "green" }}>Subscribed !</div>} */}
        </div>
      )}
    />
  );

export default MailChimpForm;


// class MailChimpForm extends Component {

//     state = {
//         email: ''
//       }

//     render () {
//         return(

//             <GridContainer>
//             <h5>Je souhaite m'inscrire à la newsletter et être au courant des dernières infos de PainkillAR</h5>
//             {/* <!-- Begin MailChimp Signup Form --> */}
//                      <div class="email-form-div" id="mc_embed_signup">
//                        <form action="https://painkillar.us17.list-manage.com/subscribe/post?u=6b0c22566fb441808566b7d9c&amp;id=19147e362d" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="form-group email-form" target="_blank" novalidate>
//                            {/* <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups--> */}
//                            <div style={{position: "absolute", left: "-5000px"}} ariaHidden="true">
//                                <input type="text" name="b_6b0c22566fb441808566b7d9c_19147e362d" tabindex="-1" value=""/>
//                                </div>
// 	                         <input type="email" value="" name="EMAIL" class="form-control email-input" id="mce-EMAIL" placeholder="Entrez votre email" required />
//                            <button type="submit" class="btn submit-btn" name="subscribe" id="mc-embedded-subscribe">Je m'inscris</button>
//                         </form>
//                       </div>

//                       {/* <!--End mc_embed_signup--> */}

//             {/* <GridItem xs={12} sm={12} md={4}>
//                 <CustomInput
//                 labelText="Mon email"
//                 id="mce-EMAIL"
//                 formControlProps={{
//                     fullWidth: true
//                 }}
//                 inputProps={{
//                     endAdornment: (<InputAdornment position="end"><Email/></InputAdornment>)
//                 }}
//                 value={this.state.content}
//             />
//                             </GridItem>
//                             <GridItem xs={12} sm={12} md={4}>
//       <Button
//                 color="rose"
//                 size="lg"
//                 target="_blank"
//                 rel="noopener noreferrer"
//                 type="submit"
//               >
//                 Je m'inscris
//               </Button>
//         </GridItem> */}

//           </GridContainer>

//         )
//       }
// }

// export default MailChimpForm;


// <!-- Begin Mailchimp Signup Form -->
// <link href="//cdn-images.mailchimp.com/embedcode/classic-10_7.css" rel="stylesheet" type="text/css">
// <style type="text/css">
// 	#mc_embed_signup{background:#fff; clear:left; font:14px Helvetica,Arial,sans-serif; }
// 	/* Add your own Mailchimp form style overrides in your site stylesheet or in this style block.
// 	   We recommend moving this block and the preceding CSS link to the HEAD of your HTML file. */
// </style>
// <style type="text/css">
// 	#mc-embedded-subscribe-form input[type=checkbox]{display: inline; width: auto;margin-right: 10px;}
// 	#mergeRow-gdpr {margin-top: 20px;}
// 	#mergeRow-gdpr fieldset label {font-weight: normal;}
// 	#mc-embedded-subscribe-form .mc_fieldset{border:none;min-height: 0px;padding-bottom:0px;}
// </style>
// <div id="mc_embed_signup">
// <form action="https://painkillar.us17.list-manage.com/subscribe/post?u=6b0c22566fb441808566b7d9c&amp;id=19147e362d" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
//     <div id="mc_embed_signup_scroll">
// 	<h2>Subscribe</h2>
// <div class="indicates-required"><span class="asterisk">*</span> indicates required</div>
// <div class="mc-field-group">
// 	<label for="mce-EMAIL">Email Address  <span class="asterisk">*</span>
// </label>
// 	<input type="email" value="" name="EMAIL" class="required email" id="mce-EMAIL">
// </div>
// <div class="mc-field-group">
// 	<label for="mce-FNAME">First Name </label>
// 	<input type="text" value="" name="FNAME" class="" id="mce-FNAME">
// </div>
// <div class="mc-field-group">
// 	<label for="mce-LNAME">Last Name </label>
// 	<input type="text" value="" name="LNAME" class="" id="mce-LNAME">
// </div>
// <div id="mergeRow-gdpr" class="mergeRow gdpr-mergeRow content__gdprBlock mc-field-group">
//     <div class="content__gdpr">
//         <label>Marketing Permissions</label>
//         <p>Please select all the ways you would like to hear from PainkillAR:</p>
//         <fieldset class="mc_fieldset gdprRequired mc-field-group" name="interestgroup_field">
// 		<label class="checkbox subfield" for="gdpr_43693"><input type="checkbox" id="gdpr_43693" name="gdpr[43693]" value="Y" class="av-checkbox "><span>Email</span> </label><label class="checkbox subfield" for="gdpr_43697"><input type="checkbox" id="gdpr_43697" name="gdpr[43697]" value="Y" class="av-checkbox "><span>Direct Mail</span> </label><label class="checkbox subfield" for="gdpr_43701"><input type="checkbox" id="gdpr_43701" name="gdpr[43701]" value="Y" class="av-checkbox "><span>Customized Online Advertising</span> </label>
//         </fieldset>
//         <p>You can unsubscribe at any time by clicking the link in the footer of our emails. For information about our privacy practices, please visit our website.</p>
//     </div>
//     <div class="content__gdprLegal">
//         <p>We use Mailchimp as our marketing platform. By clicking below to subscribe, you acknowledge that your information will be transferred to Mailchimp for processing. <a href="https://mailchimp.com/legal/" target="_blank">Learn more about Mailchimp's privacy practices here.</a></p>
//     </div>
// </div>
// 	<div id="mce-responses" class="clear">
// 		<div class="response" id="mce-error-response" style="display:none"></div>
// 		<div class="response" id="mce-success-response" style="display:none"></div>
// 	</div>    <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
//     <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_6b0c22566fb441808566b7d9c_19147e362d" tabindex="-1" value=""></div>
//     <div class="clear"><input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="button"></div>
//     </div>
// </form>
// </div>
// <script type='text/javascript' src='//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js'></script><script type='text/javascript'>(function($) {window.fnames = new Array(); window.ftypes = new Array();fnames[0]='EMAIL';ftypes[0]='email';fnames[1]='FNAME';ftypes[1]='text';fnames[2]='LNAME';ftypes[2]='text';fnames[3]='ADDRESS';ftypes[3]='address';fnames[4]='PHONE';ftypes[4]='phone';}(jQuery));var $mcj = jQuery.noConflict(true);</script>
// <!--End mc_embed_signup--></div>