import React from "react";
// react component for creating beautiful carousel
import Carousel from "react-slick";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import LocationOn from "@material-ui/icons/LocationOn";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";

import lci from "assets/img/clients/lci.png";
import lciLogo from "assets/img/clients/lci-logo.png";

import rtl from "assets/img/clients/rtl-ftur.png";
import rtlLogo from "assets/img/clients/rtl-futur.png";

import arte from "assets/img/clients/arte-28-min.png";
import arteLogo from "assets/img/clients/arte.png";
import emmaus from "assets/img/clients/emmaus-connect.png";
import emmausLogo from "assets/img/clients/emmaus-connect.jpg";

import styles from "assets/jss/material-kit-react/views/componentsSections/carouselStyle.js";
import { Button } from "@material-ui/core";

const useStyles = makeStyles(styles);

export default function PressSection() {
  const classes = useStyles();
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true
  };
  return (
    <div id="Press" className={classes.section}>
        <h2 className={classes.title}>Presse</h2>
        <h4 className={classes.title}>Cliquez sur l'image pour accéder à l'article</h4>
      <div className={classes.container}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={8} className={classes.marginAuto}>
            <Card carousel>
              <Carousel {...settings}>
                <div>
                <a href="https://www.lci.fr/high-tech/vivatech-2019-painkillar-vr-anti-douleur-casque-realite-virtuelle-viva-technology-salon-innovation-paris-expo-2121090.html?fbclid=IwAR2WzoRyIF4r7oUUj7nR489Ki5F8PS0aakJYtdKpiBEIDAGyLgHj5DNKNZw"
                  target="_blank">
                  <img src={lci} alt="First slide" className="slick-image" />
                  </a>
                  <div className="slick-caption">
                  <GridContainer>
          <GridItem xs={12} sm={12} md={2} className={classes.marginAuto}>
                  <img src={lciLogo} style={{width: "100%", height: "100%"}}/>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={10} className={classes.marginAuto}>

                    <Button 
                    style={{color: '#FFFF'}}
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.lci.fr/high-tech/vivatech-2019-painkillar-vr-anti-douleur-casque-realite-virtuelle-viva-technology-salon-innovation-paris-expo-2121090.html?fbclid=IwAR2WzoRyIF4r7oUUj7nR489Ki5F8PS0aakJYtdKpiBEIDAGyLgHj5DNKNZw">
                      Voir notre interview sur LCI
                    </Button>
                    </GridItem>
                    </GridContainer>
                  </div>
                </div>
                <div>
                  <a href="https://twitter.com/rtl_futur/status/1129287259252043776" target="_blank">
                  <img
                    src={rtl}
                    alt="Second slide"
                    className="slick-image"
                  />
                  </a>
                  <div className="slick-caption">
                  <GridContainer>
          <GridItem xs={12} sm={12} md={2} className={classes.marginAuto}>
                  <img src={rtlLogo} style={{width: "100%", height: "100%"}}/>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={10} className={classes.marginAuto}>

                    <Button
                        style={{color: '#FFFF'}}
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://twitter.com/rtl_futur/status/1129287259252043776"
                    >
                    Voir notre interview pour RTL-Futur
                    </Button>
                    </GridItem>
                    </GridContainer>
                  </div>
                </div>
                <div>
                  <a href="https://twitter.com/28minutes/status/1129817426982449157" target="_blank">
                  <img src={arte} alt="Third slide" className="slick-image" />
                  </a>
                  <div className="slick-caption">
                  <GridContainer>

                  <GridItem xs={12} sm={12} md={2} className={classes.marginAuto}>
                  <img src={arteLogo} style={{width: "100%", height: "100%"}}/>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={10} className={classes.marginAuto}>

                    <Button
                        style={{color: '#FFFF'}}
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://twitter.com/28minutes/status/1129817426982449157"
                    >
                    Voir la vidéo sur Arte-28'
                    </Button>
                    </GridItem>
                    </GridContainer>
                  </div>
                </div>
                <div>
                  <a href="https://www.inclusion-numerique.fr/realites-virtuelles-ou-virtualites-reelles/" target="_blank">
                    <img src={emmaus} alt="Fourth slide" className="slick-image" />
                  </a>
                  <div className="slick-caption">
                  <GridContainer>

                    <GridItem xs={12} sm={12} md={2} className={classes.marginAuto}>
                    <img src={emmausLogo} style={{width: "100%", height: "100%"}}/>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={10} className={classes.marginAuto}>

                    <Button
                        style={{}}
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.inclusion-numerique.fr/realites-virtuelles-ou-virtualites-reelles/"
                    >
                    Lire l'article sur Emmaüs Connect
                    </Button>
                    </GridItem>
                    </GridContainer>
                  </div>
                </div>
              </Carousel>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
